import { useEffect, useState } from "react";
import CouponKakaoForm from "../form/coupon/CouponKakaoForm";
import BlueButton from "../BlueButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { renderCouponCompanyName } from "../../libs/helper";
import {
  defaultNotificationMessage,
  directNotificationMessage,
} from "../../constants/coupon";
import CouponResultForm from "../form/coupon/CouponResultForm";

const EditCouponContent = ({
  editId,
  tabs,
  activeTab,
  data,
  onChange,
  originalData,
  isValidated,
}) => {
  const [notificationImage, setNotificationImage] = useState();
  const [notificationMessage, setNotificationMessage] = useState();
  const [couponImage, setCouponImage] = useState();
  const [couponType, setCouponType] = useState();
  const [additionalTitle, setAdditionalTitle] = useState();
  const [additionalDescription, setAdditionalDescription] = useState();

  const handleChangeIntroValue = (data) => {
    const { notificationImage, message } = data;
    setNotificationImage(notificationImage);
    setNotificationMessage(message);
  };

  const handleChangeResultValue = (data) => {
    const { couponImage, additionalTitle, additionalDescription } = data;
    setCouponImage(couponImage);
    setAdditionalTitle(additionalTitle);
    setAdditionalDescription(additionalDescription);
  };

  useEffect(() => {
    onChange({
      notificationImage,
      notificationMessage,
      couponImage,
      additionalTitle,
      additionalDescription,
      couponType,
    });
  }, [
    additionalDescription,
    additionalTitle,
    couponImage,
    notificationImage,
    notificationMessage,
    couponType,
    onChange,
  ]);
  const renderWrap = (activeTab) => {
    if (activeTab === 1) {
      return (
        <CouponKakaoForm
          isValidated={isValidated}
          couponType={couponType}
          notificationImage={notificationImage}
          message={notificationMessage}
          onChange={(data) => handleChangeIntroValue(data)}
        />
      );
    } else if (activeTab === 2) {
      return (
        <CouponResultForm
          isValidated={isValidated}
          couponImage={couponImage}
          additionalTitle={additionalTitle}
          additionalDescription={additionalDescription}
          onChange={(data) => handleChangeResultValue(data)}
          couponData={originalData}
        />
      );
    } else {
      return "";
    }
  };

  const renderCouponInfo = (data) => {
    return [
      renderCouponCompanyName(data.company),
      data.name,
      data.couponTypeLabel,
      data.period.toLocaleString() + "일",
      data.price.toLocaleString() + "원",
    ].join("/");
  };
  useEffect(() => {
    if (data) {
      setCouponType(data.couponType);
      setNotificationImage(data.notificationImage);
      setNotificationMessage(
        data.notificationMessage ||
          (data.couponType === "DIRECT"
            ? directNotificationMessage
            : defaultNotificationMessage)
      );
      setCouponImage(data.couponImage);
      setAdditionalTitle(data.additionalTitle);
      setAdditionalDescription(data.additionalDescription);
    }
  }, [data]);

  return (
    <div
      className="flex rounded w-full border-gray-300 border-2"
      style={{ width: "1200px" }}
    >
      <div className="flex-col gap-12 bg-white rounded-l p-12 w-full">
        {originalData?.couponCode && (
          <div className="flex-col gap-4">
            <div className="flex w-full">
              <label className="font-medium relative">
                당첨 혜택 연결
                <span className="absolute text-red-500 -end-2">*</span>
              </label>
            </div>
            <div className="flex gap-4 w-full">
              <BlueButton className="py-2 px-6 text-sm whitespace-nowrap">
                <FontAwesomeIcon icon={faCircleCheck} className="mr-1" /> 연결
                완료
              </BlueButton>
              <input
                type="text"
                className="w-full bg-indigo-50 border border-indigo-300 text-gray-500 text-sm rounded-lg block p-2"
                value={renderCouponInfo(originalData)}
                readOnly
                required
              />
            </div>
          </div>
        )}
        <div className="flex-col">
          <div className="flex w-full">
            <label className="font-medium relative">
              당첨 내용 설정
              <span className="absolute text-red-500 -end-2">*</span>
            </label>
            <label
              className={
                "text-red-500 ml-3 text-sm transition-all " +
                (isValidated && data.coupon == null
                  ? "opacity-100"
                  : "opacity-0")
              }
            >
              <ul className="flex gap-2 ml-2">
                {(!data?.notificationImage || !data?.notificationMessage) && (
                  <li>1.{tabs[0].label}이 완료되지 않았습니다.</li>
                )}
                {!data?.couponImage && (
                  <li>2.{tabs[1].label}가 완료되지 않았습니다.</li>
                )}
              </ul>
            </label>
          </div>
          <div className="flex w-full mt-2">
            {tabs.map((tab, index) => {
              return (
                <div
                  className={
                    "flex items-center justify-center w-1/2 py-2 text-sm text-gray-600 rounded-t-lg border-b-2 " +
                    (activeTab === tab.value
                      ? "bg-indigo-100 border-indigo-500"
                      : "bg-gray-100 border-gray-300")
                  }
                >
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={"w-5 h-5 mr-2 "}
                  />
                  {index + 1}. {tab.label}
                </div>
              );
            })}
          </div>
          <div className="py-20 px-12 border-l border-b border-r border-gray-300">
            {renderWrap(activeTab)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCouponContent;
