import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getUsers = async (
    spotId = null,
    search = null,
    startDate = null,
    endDate = null,
    page = 0,
    size = 2,
    sort = "name,desc"
) => {
    const params = new URLSearchParams({
        page,
        size,
        sort,
    });
    if (spotId) params.append("spotId", spotId);
    if (search) params.append("search", search);
    if (startDate) params.append("startDate", startDate);
    if (endDate) params.append("endDate", endDate);
    const res = await fetchApi.get(
        API_URL.PARTNER_USER_LIST + `?${params.toString()}`
    );
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};
export const getUsersAll = async (
    userIdList
) => {
    const res = await fetchApi.post(API_URL.USER_LIST_ALL, {
        userIdList: userIdList
    });
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
};
