import MobilePreview from "../../MobilePreview";

const EventPrizePreview = ({eventBackgroundImage, eventImage, eventButtonImage, prizePrecautionContent, additionalButtons}) => {
    const API_HOST = process.env.REACT_APP_API_HOST
    return (
        <MobilePreview>
            <iframe
                className="relative h-full"
                src={`${API_HOST}/view/event?temp=true&prize=true` +
                `&eventBackgroundImage=${eventBackgroundImage}` +
                `&eventImage=${eventImage}` +
                `&eventButtonImage=${eventButtonImage}` +
                `&prizePrecautionContent=${prizePrecautionContent.replaceAll("\n", "-br-")}` +
                `&prizeImage=https://img.headless.co.kr/coupon.jpg` +
                `${additionalButtons?.length > 0 ? `&buttons=${additionalButtons.map((item) => item.name).join(",")}` : ""}`
                }
                title="OMOPLAY TEMP"
            />
        </MobilePreview>
    )
}

export default EventPrizePreview