import { Box, Typography, Button } from "@mui/material";
const WideTypeContent = ({ data }) => {
  const handleImageClick = () => {
    data.link ?? window.open(data.link);
  };
  return (
    <Box sx={{ width: "262px" }} className="message-content">
      <Box
        className="preview-image-wrap clickable"
        sx={{
          width: "100%",
          height: "200px",
          backgroundImage: `url(${data.image})`,
        }}
        onClick={() => handleImageClick()}
      ></Box>
      <Box className="default-message-content">
        <Typography sx={{ whiteSpace: "break-spaces" }} fontSize={12}>
          {data.content}
        </Typography>
          {
              data.messageButtons && (
                  <div className="flex" style={{padding: "8px", gap: "5px"}}>
                      {
                          data.messageButtons
                              .filter((button) => button.isLink === "link" || button.isLink === "coupon")
                              .map((button, index) => {
                                  return (
                                      <Button
                                          className="preview-button"
                                          variant="outlined"
                                          color={"grey7"}
                                          onClick={() => window.open(button.link)}
                                          sx={{ flex: 1, mt: 2, padding: "0 4px 0 0" }}
                                      >
                                          {button.name || "메시지 버튼"}
                                      </Button>
                                  );
                              })
                      }
                  </div>
              )
          }
      </Box>
    </Box>
  );
};

export default WideTypeContent;
