import { useCallback } from "react";
import InputGroup from "../form/InputGroup";
import TextInput from "../form/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faCommentSms,
} from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";
import { MESSAGE_TYPES } from "../../constants/menus";

export const MessageDefaultInfo = ({
  onChange,
  campaignName,
  messageType,
}) => {
  const handleChange = useCallback(
    (key, value) => {
      let updateValue = {
        campaignName,
        messageType,
      };
      updateValue[key] = value;
      onChange(updateValue);
    },
    [messageType, campaignName, onChange]
  );

  return (
    <div className="border rounded-lg bg-white w-[1032px] p-8 mb-20">
      <div className="flex-col divide-y">
        <InputGroup
          label={"메시지 캠페인명"}
          isRequired={true}
          className="py-8"
        >
          <p className="text-gray-500 text-sm mb-2">
            다른 메시지들과 구분하기 위한 캠페인명을 작성해 주세요.
          </p>
          <TextInput
            value={campaignName}
            placeholder="캠페인명을 입력해 주세요."
            onChange={(e) => handleChange("campaignName", e.target.value)}
          />
        </InputGroup>
        <InputGroup label={"메시지 타입"} isRequired={true} className="py-8">
          <p className="text-gray-500 text-sm mb-2">
            어떤 메시지를 보낼지 골라주세요
          </p>
          <ul className="flex w-full gap-8">
            <li className="border-indigo-500 bg-indigo-50 py-6 w-[200px] rounded-lg flex gap-2 items-center text-gray-600 border justify-center cursor-pointer">
              <div className="rounded-full bg-gray-200 w-[48px] h-[48px] flex items-center justify-center text-indigo-600">
                <FontAwesomeIcon icon={faComment} size={"lg"}></FontAwesomeIcon>
              </div>
              <p className="font-medium">친구톡</p>
            </li>
            <li className="border-gray-100 bg-gray-100 py-6 w-[200px] rounded-lg flex gap-2 items-center text-gray-400 border justify-center">
              <div className="rounded-full bg-gray-200 w-[48px] h-[48px] flex items-center justify-center text-gray-400">
                <FontAwesomeIcon
                  icon={faCommentSms}
                  size={"lg"}
                ></FontAwesomeIcon>
              </div>
              <p className="font-medium">문자</p>
            </li>
          </ul>
        </InputGroup>
        <InputGroup
          label={"친구톡 타입 고르기"}
          isRequired={true}
          className="py-8"
        >
          <p className="text-gray-500 text-sm mb-2">
            어떤 메시지를 보내실지 골라주세요.
          </p>
          <div className="grid grid-cols-2 gap-4 text-sm">
            {MESSAGE_TYPES.map((message_type, index) => (
              <div
                key={"message-type" + index}
                className={
                  "flex border rounded-lg cursor-pointer " +
                  (message_type.key === messageType
                    ? "border-[#4046e3] bg-indigo-50"
                    : "border-[#dbdbea]")
                }
                onClick={() => handleChange("messageType", message_type.key)}
              >
                <Box className="w-[240px] bg-[#eaecef] min-w-[240px] rounded-l-lg">
                  <Box className="w-full ft-selection-preview-box">
                    <Box className={message_type.imageClass}></Box>
                  </Box>
                </Box>
                <div className="flex-col justify-center p-8 text-gray-600 text-xs">
                  <h5 className="font-medium text-sm">{message_type.title}</h5>
                  <p className="mt-2">{message_type.description}</p>
                  <button className="text-indigo-600 w-fit mt-2">
                    작성하기 >
                  </button>
                </div>
              </div>
            ))}
          </div>
        </InputGroup>
      </div>
    </div>
  );
};

export default MessageDefaultInfo;
