import { Box, Typography, Button } from "@mui/material";
const DefaultTypeContent = ({ data }) => {
  return (
    <Box sx={{ width: "220px" }} className="message-content">
      {data.image && (
        <Box
          className="preview-image-wrap"
          sx={{
            width: "100%",
          }}
        >
          {data.image && (
            <img
              src={data.image}
              style={{
                width: "100%",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            ></img>
          )}
        </Box>
      )}
      <Box className="default-message-content">
        <Typography sx={{ whiteSpace: "break-spaces" }} fontSize={12}>
          {data.content}
        </Typography>
        {data.messageButtons &&
          data.messageButtons
            .filter((button) => button.isLink === "link" || button.isLink === "coupon")
            .map((button, index) => {
              return (
                <Button
                  className="preview-button"
                  variant="outlined"
                  color={"grey7"}
                  onClick={() => window.open(button.link)}
                  sx={{ mt: index === 0 ? 2 : 1 }}
                >
                  {button.name || "메시지 버튼"}
                </Button>
              );
            })}
      </Box>
    </Box>
  );
};

export default DefaultTypeContent;
