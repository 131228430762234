import MobilePreview from "../../MobilePreview";

const EventRoulettePreview = ({eventBackgroundImage, eventImage, eventButtonImage}) => {
    const API_HOST = process.env.REACT_APP_API_HOST

    return (
        <MobilePreview>
            <iframe
                className="relative h-full"
                src={`${API_HOST}/view/event?temp=true&eventBackgroundImage=${eventBackgroundImage}&eventImage=${eventImage}&eventButtonImage=${eventButtonImage}`}
                title="OMOPLAY TEMP"
            />
        </MobilePreview>
    )
}

export default EventRoulettePreview