import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AllSpotsPage from "./pages/AllSpots";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PromotionPage from "./pages/PromotionPage";
import Spot from "./pages/Spot";
import UserStatistics from "./pages/UserStatistics";
import UserListPage from "./pages/Users";
import Coupons from "./pages/Coupons";
import EditCoupon from "./pages/EditCoupon";
import VolatilityMessageList from "./pages/VolatilityMessage";
import EditVolatilityMessage from "./pages/EditVolatilityMessage";
import VolatilityMessageDetail from "./pages/VolatilityMessageDetail";
import Payments from "./pages/Payments";
import "moment/locale/ko";
import "omoplay/dist/style.css"
import MessagePage from "./pages/Messages";
import AutomateMessages from "./pages/AutomateMessages";
import AutomateMessageEditPage from "./pages/AutomateMessageEditPage";
import AccountSettingPage from "./pages/AccountSettingPage";
import ProjectsPage from "./pages/Projects";
import ProjectSettings from "./pages/ProjectSettings";
import EventEdit from "./pages/EventEdit";
import EventPage from "./pages/EventPage";
import BenefitEdit from "./pages/BenefitEdit";
import ProjectDetailPage from "./pages/ProjectDetail";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AllSpotsPage />} />
        <Route path="/spot" element={<Spot />} />
        <Route path="/spots" element={<Home />} />
        <Route path="/event" element={<EventPage />} />
        <Route path="/event/edit" element={<EventEdit />} />
        <Route path="/benefit/edit" element={<BenefitEdit />} />
        <Route path="/volatility-message" element={<VolatilityMessageList />} />
        <Route
          path="/volatility-message/edit"
          element={<EditVolatilityMessage />}
        />
        <Route
          path="/volatility-message/detail"
          element={<VolatilityMessageDetail />}
        />
        <Route path="/benefit" element={<Coupons />} />
        <Route path="/coupon/edit" element={<EditCoupon />} />
        <Route path="/promotion" element={<PromotionPage />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/users" element={<UserListPage />} />
        <Route path="/messages" element={<MessagePage />} />
        <Route path="/users-statistics" element={<UserStatistics />} />
        <Route path="/automate-message" element={<AutomateMessages />} />
        <Route path="/automate-message/edit" element={<AutomateMessageEditPage />} />
        <Route path="/project" element={<ProjectsPage />} />
        <Route path="/project/edit" element={<ProjectSettings />} />
        <Route path="/project/detail" element={<ProjectDetailPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/account" element={<AccountSettingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
