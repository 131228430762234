import {
  Box,
  Divider,
  Typography,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { API_URL, DEFAULT_HEADER } from "../../../../constants/axios";
import TextSettingInput from "../../../form/TextSettingInput";
import SettingFormLabel from "../../../form/SettingFormLabel";
import SettingItemForm from "../../../form/SettingItemForm";
import GrayButton from "../../../GrayButton";
import NormalButton from "../../../NormalButton";
import SuspensePopup from "../../../SuspensePopup";
import CouponConnectionForm from "../../../form/CouponConnectionForm";

const WideListMessageSetting = ({ type, data, onChange }) => {
  const [isAdd, setIsAdd] = useState(data.isAdd);
  const [title, setTitle] = useState(data.title);
  const [messageButtons, setMessageButtons] = useState(
    data.messageButtons || [{ isLink: 'none' }]
  );

  const initalizeItem = {
    iamge: null,
    content: null,
    link: null,
  };
  const [mainItem, setMainItem] = useState(data.mainItem || initalizeItem);
  const [listItems, setListItems] = useState(
    data.listItems || [initalizeItem, initalizeItem]
  );
  const [isOpenConnectionPopup, setOpenConnectionPopup] = useState(null);

  const changeMessageButton = (index, key, value) => {
    const updateValue = [...messageButtons];
    updateValue[index][key] = value;
    setMessageButtons(updateValue);
  };
  const checkUrl = (url) => {
    window.open(url);
  };

  const handleChange = () => {
    onChange({
      isAdd,
      title,
      mainItem,
      listItems,
      messageButtons,
    });
  };
  const updateListItem = (index, data) => {
    let updateValue = [...listItems];
    updateValue[index] = data;
    setListItems(updateValue);
  };

  const addListItem = () => {
    let updateValue = [...listItems];
    updateValue.push(initalizeItem);
    setListItems(updateValue);
  };

  const handleListItemDelete = () => {
    let updateValue = [...listItems];
    updateValue.pop();
    setListItems(updateValue);
  };
  const handleMappingCouponUpdate = (index, coupon) => {
    changeMessageButton(index, "coupon", coupon);
    setOpenConnectionPopup(null);
  };

  useEffect(() => {
    handleChange();
  }, [isAdd, title, mainItem, listItems, messageButtons]);
  return (
    <>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 2 }}>
        와이드 리스트형 작성하기
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Box>
        <FormControl>
          <SettingFormLabel label={"광고 표기 여부"} />
          <RadioGroup
            row
            aria-labelledby="is_add"
            name="is_add"
            value={isAdd ? "add" : "info"}
            onChange={(e) => setIsAdd(e.target.value === "add")}
          >
            <FormControlLabel
              value="add"
              control={<Radio />}
              label="광고성"
            />
            <FormControlLabel
              value="info"
              control={<Radio />}
              label="정보성"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Divider sx={{ mt: 2, mb: 4 }} />
      <Box>
        <TextSettingInput
          label={"타이틀"}
          isRequired={true}
          inputProps={{ maxLength: 25 }}
          size={"small"}
          value={title}
          placeholder={"타이틀을 작성해주세요"}
          onChange={(e) => setTitle(e.target.value)}
        />
      </Box>
      <Divider sx={{ mt: 3, mb: 4 }} />
      <Box>
        <SettingFormLabel label={"메인 아이템"} isRequired />
        <SettingItemForm
          type={type}
          onChange={(data) => setMainItem(data)}
          data={mainItem}
          isMain={true}
          id={"wide-main"}
          sx={{ mt: 1 }}
        />
      </Box>
      {listItems.map((listItem, index) => {
        return (
          <Box sx={{ mt: 2 }} key={"wide-list" + index}>
            <SettingFormLabel label={"리스트 항목 " + (index + 1)} isRequired />
            <SettingItemForm
              type={type}
              onChange={(data) => updateListItem(index, data)}
              isDeletable={index == 2}
              data={listItem}
              id={"wide-list" + index}
              onDelete={() => handleListItemDelete()}
              sx={{ mt: 1 }}
            />
          </Box>
        );
      })}
      {listItems.length == 2 && (
        <Box sx={{ mt: 2 }}>
          <NormalButton
            variant="outlined"
            className="py-1.5 w-full"
            onClick={() => addListItem()}
          >
            + 리스트 항목 추가
          </NormalButton>
        </Box>
      )}
      <Divider sx={{ mt: 3, mb: 4 }} />
      {messageButtons.map((messageButton, index) => {
        return (
          <Box key={"wide-list-message-button-" + index}>
            <Box>
              <FormControl>
                <SettingFormLabel
                  label={
                    messageButtons.length === 1
                      ? "메시지 버튼"
                      : "메시지 버튼 " + (index + 1)
                  }
                />
                <RadioGroup
                  row
                  aria-labelledby={"message_button_" + index}
                  name={"message_button_" + index}
                  value={messageButton.isLink || "none"}
                  onChange={(e) =>
                    changeMessageButton(index, "isLink", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="없음"
                  />
                  <FormControlLabel
                    value="link"
                    control={<Radio />}
                    label="링크"
                  />
                  <FormControlLabel
                    value="coupon"
                    control={<Radio />}
                    label="쿠폰"
                  />
                </RadioGroup>
              </FormControl>
              {messageButton.isLink === "link" && (
                <Box>
                  <div className="relative">
                    <TextField
                      size={"small"}
                      fullWidth
                      placeholder="버튼명을 입력해 주세요."
                      inputProps={{ maxLength: 8 }}
                      value={messageButton.name}
                      onChange={(e) =>
                        e.target.value.length <= 8 &&
                        changeMessageButton(index, "name", e.target.value)
                      }
                    />
                    <p className="absolute right-2 bottom-3 text-xs text-gray-400">
                      {messageButton.name?.length || 0}/8자
                    </p>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      mt: 1,
                      mb: 2,
                    }}
                  >
                    <TextField
                      sx={{ flex: 1 }}
                      size={"small"}
                      placeholder="연결할 페이지의 URL을 입력해주세요."
                      value={messageButton.link}
                      onChange={(e) =>
                        changeMessageButton(index, "link", e.target.value)
                      }
                    />
                    <NormalButton onClick={() => checkUrl(messageButton.link)}>
                      URL 확인
                    </NormalButton>
                  </Box>
                  <Box>
                    <SettingFormLabel label={"PC 전용 링크"} />
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        mt: 1,
                      }}
                    >
                      <TextField
                        sx={{ flex: 1 }}
                        size={"small"}
                        placeholder="연결할 페이지의 URL을 입력해주세요."
                        value={messageButton.pcLink}
                        onChange={(e) =>
                          changeMessageButton(index, "pcLink", e.target.value)
                        }
                      />
                      <NormalButton
                        onClick={() => checkUrl(messageButton.link)}
                      >
                        URL 확인
                      </NormalButton>
                    </Box>
                  </Box>
                </Box>
              )}
              {messageButton.isLink === "coupon" && (
                <Box>
                  <TextField
                    size={"small"}
                    fullWidth
                    placeholder="쿠폰명을 입력해 주세요."
                    value={messageButton.name}
                    onChange={(e) =>
                      changeMessageButton(index, "name", e.target.value)
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      mt: 1,
                    }}
                  >
                    <TextField
                      sx={{ flex: 1 }}
                      size={"small"}
                      placeholder="연결할 쿠폰을 선택해주세요"
                      value={messageButton.coupon?.name}
                      disabled={true}
                      readOnly
                    />
                    <NormalButton
                      variant={"outlined"}
                      onClick={() => setOpenConnectionPopup(index)}
                    >
                      쿠폰 연결하기
                    </NormalButton>
                  </Box>
                </Box>
              )}
            </Box>
            {index + 1 < messageButtons.length && (
              <Divider sx={{ mt: 2, mb: 4 }} />
            )}
          </Box>
        );
      })}
      {isOpenConnectionPopup != null && (
        <SuspensePopup
          isEditPopup={true}
          title={"쿠폰 연결하기"}
          size={"w-[1200px]"}
          onClose={() => setOpenConnectionPopup(null)}
        >
          <CouponConnectionForm
            onClick={(coupon) =>
              handleMappingCouponUpdate(isOpenConnectionPopup, coupon)
            }
            used={true}
          />
        </SuspensePopup>
      )}
    </>
  );
};

export default WideListMessageSetting;
