import { useCallback, useEffect, useState } from "react";
import BenefitMessagePreview from "../../layout/BenefitMessagePreview";
import { fileUpload, messageFileUpload } from "../../../api/file/file.api";
import HighlightWithinTextarea from "react-highlight-within-textarea";
import {
  defaultNotificationMessage,
  directNotificationMessage,
} from "../../../constants/coupon";
import NormalButton from "../../NormalButton";
import OverlayBadge from "../benefit/OverlayBadge";
import GrayButton from "../../GrayButton";
const CouponKakaoForm = ({
  onChange,
  isValidated,
  couponType,
  notificationImage,
  message,
}) => {
  const [uploadFileName, setUploadFileName] = useState();
  const [isVariablePopup, setVariablePopup] = useState(false);
  useEffect(() => {
    if (notificationImage && !uploadFileName) {
      setUploadFileName(decodeURI(notificationImage.split("/").reverse()[0]));
    }
  }, [notificationImage]);

  const handleChange = useCallback(
    (key, value) => {
      let updateValue = {
        notificationImage,
        message,
      };
      updateValue[key] = value;
      onChange(updateValue);
    },
    [message, notificationImage, onChange]
  );

  const onChangeFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      handleFileUpload(file);
    }
  };

  const handleFileUpload = useCallback(
    async (file) => {
      await messageFileUpload(
        "default",
        file,
        (res) => {
          if (res.data.code === "0") {
            handleChange("notificationImage", res.data.imageUrl);
            setUploadFileName(file.name);
          } else {
            alert(res.data.message);
          }
        },
        (err) => {
          alert(err.message);
        }
      );
    },
    [handleChange]
  );

  const addVariable = (text) => {
    handleChange("message", message + `[${text}]`);
    setVariablePopup(false);
  };

  const renderFormWrap = (
    <div className="flex-col gap-20 w-full">
      <ul className="flex-col gap-8">
        <li>
          <div className="flex items-center mb-1">
            <label className="text-sm font-semibold text-gray-900 relative flex items-center">
              <div className="bg-black text-white text-xs rounded-full h-5 w-5 text-center font-medium leading-5 mr-2">
                1
              </div>
              당첨 이미지
              <span className="absolute text-red-500 -end-2">*</span>
            </label>
          </div>
          <p className="text-gray-500 text-sm mb-2">
            카톡으로 전송될 당첨 이미지를 설정해 주세요
          </p>
          <div className="flex gap-4">
            <input
              type="text"
              className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
              placeholder="업로드할 파일 선택"
              maxLength={30}
              readOnly
              value={uploadFileName}
              required
            />
            <label
              htmlFor={"intro_background"}
              className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
            >
              첨부
            </label>
            <input
              type="file"
              id={"intro_background"}
              className="hidden"
              onChange={onChangeFile}
            />
          </div>
          <p className="text-gray-400 text-xs mt-4">
            권장 사이즈 : 500 X 250px / 지원파일 : JPG, JPEG, PNG (최대 2MB)
          </p>
        </li>
        <li>
          <div className="flex items-center mb-1">
            <label className="text-sm font-semibold text-gray-900 relative flex items-center">
              당첨 안내 설정
              <span className="absolute text-red-500 -end-2">*</span>
            </label>
          </div>
          <p className="text-gray-500 text-sm mb-2">
            쿠폰 당첨 내용을 설정해 주세요.
          </p>
          <div className="flex-col gap-4">
            <div className="block w-full p-2 pb-6 pr-8 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-indigo-500 focus:border-indigo-500 relative">
              <HighlightWithinTextarea
                value={message || ""}
                onChange={(value) =>
                  handleChange("message", value.slice(0, 400))
                }
                highlight={[
                  {
                    highlight: /\[[당첨 혜택|쿠폰 사용기한]+\]/g,
                    className: "variable_highlight",
                  },
                ]}
                className=""
              />
              <span className="absolute end-3 bottom-2 text-xs text-gray-400">
                {message?.length || 0}/400자
              </span>
            </div>
            <div className="flex justify-end relative">
              <NormalButton
                className="whitespace-nowrap px-4 py-2 text-sm"
                onClick={() => setVariablePopup(!isVariablePopup)}
              >
                + 변수 설정
              </NormalButton>
              {isVariablePopup && (
                <ul className="absolute -bottom-24 right-0 bg-white rounded-lg text-sm shadow-lg border border-gray-200 text-gray-600 ">
                  <li
                    className="hover:bg-gray-50 cursor-pointer py-3 px-6 rounded-t-lg"
                    onClick={() => addVariable("당첨 혜택")}
                  >
                    당첨 혜택
                  </li>
                  <li
                    className="hover:bg-gray-50 cursor-pointer py-3 px-6 rounded-b-lg"
                    onClick={() => addVariable("쿠폰 사용기한")}
                  >
                    쿠폰 사용기한
                  </li>
                </ul>
              )}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
  return (
    <BenefitMessagePreview
      preview={
        <div className="relative h-full bg-repeat-round bg-contain bg-[#ABC1D1] p-4 max-h-[460px] overflow-auto">
          <h4 className="font-medium text-gray-800 pl-2 py-2">
            당첨 카카오톡 안내
          </h4>
          <div className="flex w-full flex-col justify-center bg-white rounded-lg p-2.5">
            <div className="w-full relative ">
              <div className="bg-black opacity-50 absolute inset-0 h-ufll rounded"></div>
              <img src={notificationImage} />
              <div className="absolute left-2 top-2">
                <OverlayBadge>1</OverlayBadge>
              </div>
            </div>
            <div className="mt-2 text-xs whitespace-pre-line">{message}</div>

            <button className="bg-[#F5F5F5] py-1 text-[#474A4E] text-sm font-medium rounded mt-4">
              {couponType === "DIRECT" ? "직원 확인하기" : "바코드 확인하기"}
            </button>
          </div>
        </div>
      }
      content={renderFormWrap}
    />
  );
};

export default CouponKakaoForm;
