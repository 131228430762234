import Loading from "./Loading";

const BlueButton = ({
  children = "",
  isLoading,
  type = "button",
  disabled = false,
  className = "",
  onClick = () => {
    return;
  },
}) => {
  return (
    <div className="flex items-center justify-between">
      <button
        className={
          "border border-indigo-300 bg-indigo-50 hover:bg-indigo-300 text-indigo-600 font-medium rounded focus:outline-none focus:shadow-outline text-center transition " +
          className
        }
        type={type}
        disabled={disabled || isLoading}
        onClick={() => onClick()}
      >
        {isLoading ? (
          <div className="flex justify-center">
            <p className="text-white fill-gray-500 m-auto">
              <Loading className="w-6 h-6" />
            </p>
          </div>
        ) : (
          children
        )}
      </button>
    </div>
  );
};
export default BlueButton;
