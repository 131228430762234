import DateChart from "../DateChart";
import DateStatistics from "../DateStatistics";
import {useEffect, useState} from "react";
import {defaultLineChartConfig, defaultPieChartConfig} from "../../constants/chart_config";

const Customers = ({customers}) => {
    const [customerChart, setCustomerChart] = useState({
        ...defaultLineChartConfig,
    });
    const [customerPieChart, setCustomerPieChart] = useState({
        ...defaultPieChartConfig,
    });
    const [customerPieLabels, setCustomerPieLabels] = useState([]);

    useEffect(() => {
        if (customers.dateGroups?.length > 0) {
            const dateGroups = customers.dateGroups
            const entryPointGroups = customers.entryPointGroups
            const customerConfig = { ...defaultLineChartConfig };

            customerConfig.series[0].data = dateGroups.map((row) => {
                return { x: row.createdAt, y: row.joinCount };
            });
            setCustomerChart(customerConfig)

            const customerRangeConfig = { ...defaultPieChartConfig };
            customerRangeConfig.series = [];

            const pieData = entryPointGroups.map(entryPoint => {
                return (
                    {
                        name: entryPoint.name,
                        joinCount: entryPoint.joinCount,
                    }
                )
            })
            const sortedPie = Object.entries(pieData)
                .sort(([, a], [, b]) => {
                    return b.joinCount - a.joinCount;
                })
                .reduce((r, [k, v]) => {
                    return { ...r, [v.name]: v.joinCount };
                }, {});

            let etcCount = 0;
            Object.values(sortedPie).map((value, index) => {
                if (index > 9) {
                    etcCount += 10;
                }
            });
            const excludeOver10PieData = {};
            Object.keys(sortedPie).every((key, index) => {
                if (index <= 9) {
                    excludeOver10PieData[key] = sortedPie[key];
                    return true;
                } else {
                    excludeOver10PieData["기타"] = etcCount;
                    return false;
                }
            });
            customerRangeConfig.series = Object.values(excludeOver10PieData);
            customerRangeConfig.options.labels = Object.keys(excludeOver10PieData);

            setCustomerPieChart(customerRangeConfig)
            setCustomerPieLabels(sortedPie);
        }
    }, [customers])

    return (
        <DateStatistics keyName={"customers"} cards={[
            {
                label: "참여 고객",
                value: customerPieChart.series.reduce((a, b) => b + a, 0),
            },
        ]}>
            <div className="w-full flex border divide-x border-gray-300 divide-gray-300">
                <div className="w-1/2 p-4">
                    <DateChart
                        keyName={"customer_date_chart"}
                        chartConfig={customerChart}
                    />
                </div>
                <div className="w-1/2 flex gap-4 items-center p-4">
                    <div className="w-1/2">
                        <DateChart
                            keyName={"customer_pie_date_chart"}
                            chartConfig={customerPieChart}
                        />
                    </div>
                    <div className="w-1/2 h-full flex items-center">
                        <ul
                            className="w-full bg-white rounded-lg divide-y overflow-y-auto"
                            style={{ maxHeight: "360px" }}
                        >
                            {Object.entries(customerPieLabels).map(
                                ([name, joinCount], index) => {
                                    const matchColor =
                                        customerPieChart.options.colors[index] || "#999999";
                                    return (
                                        <li
                                            className="py-2 px-4 text-xs flex justify-between text-gray-500"
                                            key={"customer-pie-label" + index}
                                        >
                                            <div className="flex gap-2">
                                                <div
                                                    className="rounded-full w-4 h-4"
                                                    style={{
                                                        backgroundColor: matchColor,
                                                    }}
                                                ></div>
                                                {name}
                                            </div>
                                            <div>
                                                {joinCount}명 (
                                                {Math.round(
                                                    (joinCount /
                                                        customerPieChart.series.reduce(
                                                            (a, b) => b + a,
                                                            0
                                                        )) *
                                                    1000
                                                ) / 10}
                                                %)
                                            </div>
                                        </li>
                                    );
                                }
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </DateStatistics>
    )
}

export default Customers