import { faMessage } from "@fortawesome/free-solid-svg-icons";
import SettingsLayout from "../components/layout/SettingsLayout";
import EditVolatilityMessage from "../components/page/EditVolatilityMessage";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import {
  getContentRequired,
  saveNotification,
  updateNotification,
} from "../api/message/volatility-message.api";
import { cloneDeep } from "lodash";

const tabs = [
  { label: "기본 설정", value: "default" },
  { label: "콘텐츠 작성하기", value: "content" },
  { label: "대상 및 발송일정", value: "setting" },
  { label: "최종확인", value: "confirm" },
];
const HomePage = () => {
  const [query] = useSearchParams();
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [data, setData] = useState({});
  const [isReserved, setReserved] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false)

  const handleConfirm = async (status) => {
    setIsConfirm(true)
    const request = {
      campaignName: data.campaignName,
      messageType: data.messageType,
      filters: convertFilter(data.targetFilter),
      body: data.data,
      reservedAt:
        data.reserveDate.date &&
        `${data.reserveDate.date?.startDate} ${String(
          data.reserveDate.hour
        ).padStart(2, "0")}:${String(data.reserveDate.min).padStart(
          2,
          "0"
        )}:00`,
    };
    const id = query.get("id");
    if (id != null) {
      await updateNotification(id, status, request).then((res) => {
        if (res.data.code === 5000) {
          alert("수정에 실패했습니다. 이미 메시지가 발송중입니다.")
        } else {
          alert(
              status === "RESERVED" ? (
                  isReserved
                      ? "예약 발송이 등록되었습니다." : "메시지가 발송 되었습니다") : "임시 저장되었습니다."
          );
        }
        window.location.replace("/volatility-message");
      })
    } else {
      await saveNotification(status, request).then(() => {
        alert(
            status === "RESERVED" ? (
                isReserved
                    ? "예약 발송이 등록되었습니다." : "메시지가 발송 되었습니다") : "임시 저장되었습니다."
        );
        window.location.replace("/volatility-message");
      })
    }
  };

  const convertFilter = (filters) => {
    return filters && cloneDeep(filters).map((filter) => {
      if (filter.category === "address") {
        filter.value = filter.label;
      } else if (filter.category === "createdWeek") {
        filter.value = filter.value.split("_")[1];
      }
      return {
        category: filter.category,
        value: filter.value,
        label: filter.label,
      };
    });
  };
  const getIsConfirm = (data) => {
    return (
        !isConfirm &&
        getContentRequired(data.messageType, data.data) &&
        data.campaignName != null &&
        data.campaignName.length > 0 &&
        (!isReserved || (isReserved && data.reserveDate.date != null))
      );
  };

  return (
    <SettingsLayout
      title={"메시지 보내기"}
      icon={faMessage}
      tabs={tabs}
      activeTab={activeTab}
      isTemporarySave={!isConfirm}
      onTemporarySave={() => handleConfirm("TEMPORARY")}
      onConfirm={() => handleConfirm("RESERVED")}
      onChange={setActiveTab}
      isConfirm={getIsConfirm(data)}
      confirmText={isReserved ? "예약발송하기" : "발송하기"}
      onClose={() => window.location.href = ("/volatility-message")}
    >
      <EditVolatilityMessage
        activeTab={activeTab}
        convertFilter={convertFilter}
        onChangeTab={setActiveTab}
        onChange={setData}
        setReserved={setReserved}
        isReserved={isReserved}
      />
    </SettingsLayout>
  );
};
export default HomePage;
