import {useEffect, useState} from "react";
import {Button, Table, TextInput} from "omoplay";
import SuspensePopup from "../../SuspensePopup";
import BenefitForm from "../benefit/BenefitForm";
import {getBenefit} from "../../../api/benefit/benefit.api";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NoImage from "../../../assets/images/no-image.svg"
import BenefitMessageSettingForm from "../benefit/BenefitSettingDetailForm";
import LoseBombIcon from "../../../assets/images/lose_bomb_icon.svg"
import DisabledLoseBombIcon from "../../../assets/images/disabled_lose_bomb_icon.svg"
import GrayButton from "../../GrayButton";

const EventBenefit = ({ benefitSettings, onSelectedBenefit, onDeletedBenefit, onUpdatedBenefit, onValid }) => {
  const [totalPercent, setTotalPercent] = useState(0)
  const [isOpenBenefitPopup, setOpenBenefitPopup] = useState(false)
  const [isEditBenefitPopup, setEditBenefitPopup] = useState(false)
  const [selectedBenefitList, setSelectedBenefitList] = useState([])
  const [selectedBenefitIndex, setSelectedBenefitIndex] = useState({})
  const [addBenefitId, setAddBenefitId] = useState()
  const [existsLose, setExistsLose] = useState(false)

  const headers = [
    {name: "", width: "50px"}, {name: "혜택", width: "400px"}, {name: "유효기간", align: "center"},
    {name: "당첨 확률", align: "center"}, {name: "상태", align: "center", width: "150px"},
    {name: "", width: "60px"}
  ]

  useEffect(() => {
    let sumPercent = 0
    const selectedList = benefitSettings?.map((benefit, index) => {
      sumPercent += Number(benefit.winningPercent) ?? 0
      const isLose = !benefit.id

      const openEdit = () => {
        setSelectedBenefitIndex(index)
        setEditBenefitPopup(true)
      }

      return [
        <div className="flex-center-all h-full">{benefit.id}</div>,
        <div className="flex-align-center h-full gap-4">
          <div style={{minWidth: "85px"}}><img width="85px" height="45px" src={benefit.messageImage ?? NoImage} /></div>
          <div>
            <div className="underline pointer" onClick={openEdit}>
              <span className="font-bold">{isLose ? "꽝" : benefit.name}</span><span className="text-grey-7">{benefit.retailPrice ? ` / ${benefit.retailPrice}원` : ""}</span>
            </div>
            <div className="ellipsis" style={{maxWidth: "250px"}}>{benefit.messageText}</div>
          </div>
        </div>,
        <div className="flex-center-all flex-col h-full">{benefit.periodType ? (
            benefit.periodType === "issue" ? (
                <>
                  <div>발급일로부터</div>
                  <div>{benefit.periodDays}일까지</div>
                </>
            ) : (
                <>
                  <div>프로젝트</div>
                  <div>종료시까지</div>
                </>
            )
        ) : "-"}
        </div>,
        <div className="flex-center-all h-full">
          <TextInput placeholder="총 100%" value={benefit.winningPercent}
                     type="numeric"
                     onChange={(e) => {
                       const value = e.target.value
                       const winning = value.lastIndexOf(".") ? value : Number(value)
                       if (isNaN(winning)) {
                         return
                       }
                       const updateBenefit = { ...benefitSettings.find(bs => bs.id === benefit.id) }
                       updateBenefit.winningPercent = winning
                       onUpdatedBenefit(updateBenefit)
                     }} />
        </div>,
        <div className="flex-center-all h-full">
          {
            (benefit.messageText && benefit.messageImage) && (benefit.periodType || !benefit.id)
                ? (<Button variants="default" value="설정 완료" onClick={openEdit} />)
                : (<Button variants="dark" value="설정 필요" onClick={openEdit}  />)
          }
        </div>,
        <div className="flex-center-all h-full">
          <GrayButton className="px-1.5" onClick={() => onDeletedBenefit(benefit.id)}>
            <FontAwesomeIcon icon={faXmark} />
          </GrayButton>
        </div>
      ]
    })
    setExistsLose(benefitSettings?.some(benefit => !benefit.id) ?? false)
    setSelectedBenefitList(selectedList ?? [])
    setTotalPercent(sumPercent)
  }, [benefitSettings]);

  useEffect(() => {
    if (addBenefitId) {
      getBenefit(addBenefitId).then(({data}) => {
        onSelectedBenefit(data)
      })
    }
  }, [addBenefitId]);

  useEffect(() => {
    onValid(totalPercent === 100
        && !benefitSettings?.find(settings => settings.id && !settings.periodType)
        && !benefitSettings?.find(settings => !settings.id && !settings.messageText && !settings.messageImage)) //꽝
  }, [benefitSettings, onValid, totalPercent]);

  return (
    <>
      <div className="py-12 px-4 w-full">
        <div className="flex justify-between items-center py-2">
          <div className="flex gap-4">
            <h4 className="font-semibold">혜택 설정</h4>
            <p className="text-gray-500">
              총
              <span className="text-indigo-700 font-bold mx-2">
                {benefitSettings?.length}
              </span>
              개
            </p>
          </div>
          <div className="flex gap-8">
            <div className="text-red-500 text-sm">
            {(totalPercent > 100 || totalPercent < 100) && (
                <div>당첨 확률이 100%가 되지 않았어요.</div>
            )}
            </div>
          </div>
          <div className="flex-center-all gap-2">
            <div className={"text-sm font-thin"} style={{marginRight: "50px"}}>
              당첨 확률
              <span
                  className={
                      "font-semibold ml-2 " +
                      (totalPercent > 100
                          ? "text-red-500"
                          : totalPercent < 100
                              ? "text-red-500"
                              : "text-indigo-700")
                  }
              >
                {totalPercent}
              </span>
              /100%
            </div>
            <Button
                variants="default"
                value={(
                    <div className="flex-align-center gap-1">
                      <img src={existsLose ? DisabledLoseBombIcon : LoseBombIcon} />
                      <div>꽝 추가</div>
                    </div>
                )}
                onClick={() => {
                  onSelectedBenefit("꽝")
                }}
                disabled={existsLose}
            />
            <Button
                variants="dark"
                value="+ 혜택 추가"
                onClick={() => setOpenBenefitPopup(true)}
            />
          </div>
        </div>
        <Table headers={headers} data={selectedBenefitList} />
      </div>
      {isOpenBenefitPopup && (
        <SuspensePopup
          isEditPopup={true}
          title={"혜택 연결하기"}
          onClose={() => setOpenBenefitPopup(false)}
        >
          <BenefitForm
              onSelectedBenefit={(benefitId) => {
                if (!benefitSettings?.some(benefit => benefit.id === benefitId)) {
                  setAddBenefitId(benefitId)
                }
                setOpenBenefitPopup(false)
              }}
          />
        </SuspensePopup>
      )}
      {isEditBenefitPopup && (
          <SuspensePopup
              isEditPopup={true}
              title={"혜택 당첨 설정"}
              size={"w-[1100px]"}
              onClose={() => setEditBenefitPopup(false)}
          >
            <BenefitMessageSettingForm key={benefitSettings.toString()}
                                       selectedBenefitIndex={selectedBenefitIndex}
                                       benefitList={benefitSettings}
                                       onSave={(currentBenefitIndex, messageImage, messageText, periodType, periodDays) => {
                                         const updateBenefit = { ...benefitSettings[currentBenefitIndex] }
                                         updateBenefit.messageImage = messageImage
                                         updateBenefit.messageText = messageText
                                         if (updateBenefit.id) {
                                           updateBenefit.periodType = periodType
                                           updateBenefit.periodDays = periodDays
                                         }
                                         onUpdatedBenefit(updateBenefit)
                                       }}
                                       onClose={() => setEditBenefitPopup(false)}
                                       />
          </SuspensePopup>
      )}
    </>
  )
};

export default EventBenefit;
