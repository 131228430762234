import {
  Box,
  Divider,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import TextSettingInput from "../../../form/TextSettingInput";
import SettingFormLabel from "../../../form/SettingFormLabel";
import CarouselForm from "../../../form/CarouselForm";
import { update } from "lodash";

const CarouselMessageSetting = ({ type, data, onChange }) => {
  const getInitalizeItem = () => {
    return {
      title: null,
      image: null,
      link: null,
      content: null,
      messageButtons: [
        { isRequire: true, isLink: "none" },
        { isRequire: true, isLink: "none" },
      ],
    };
  };

  const changeMessageButton = (index, key, value) => {
    const updateValue = [...data.messageButtons];
    updateValue[index][key] = value;
    handleUpdateValue("messageButtons", updateValue);
  };
  const checkUrl = (url) => {
    window.open(url);
  };

  const handleListItemAdd = () => {
    let updateValue = [...data.listItems];
    updateValue.push(getInitalizeItem());
    handleUpdateValue("listItems", updateValue);
  };
  const handleListItemDelete = (index) => {
    let updateValue = [...data.listItems];
    if (updateValue.length > 1) {
      updateValue.splice(index, 1);
    } else {
      updateValue[index] = getInitalizeItem();
    }
    handleUpdateValue("listItems", updateValue);
  };
  const handleListItemsDelete = () => {
    handleUpdateValue("listItems", [getInitalizeItem(), getInitalizeItem()]);
  };

  const handleUpdateValue = (key, value) => {
    onChange({ ...data, [key]: value });
  };
  return (
    <>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 2 }}>
        캐러셀 피드형 작성하기
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Box>
        <FormControl>
          <SettingFormLabel label={"광고 표기 여부"} />
          <RadioGroup
            row
            aria-labelledby="is_add"
            name="is_add"
            value={"add"}
          >
            <FormControlLabel
              value="add"
              control={<Radio />}
              label="광고성"
              disabled
            />
            <FormControlLabel
              value="info"
              control={<Radio />}
              label="정보성"
              disabled
            />
          </RadioGroup>
          <Typography color={"primary"} fontSize={"12px"}>
            *캐러셀 메시지는 광고 메시지로 분류됩니다.
          </Typography>
        </FormControl>
      </Box>
      <Divider sx={{ mt: 2, mb: 4 }} />
      <Box>
        <SettingFormLabel label={"캐러셀"} />
        <CarouselForm
          type={type}
          items={data.listItems || [getInitalizeItem(), getInitalizeItem()]}
          activeIndex={data.activeIndex || 0}
          onUpdate={(data) => handleUpdateValue("listItems", data)}
          onChangeActiveIndex={(index) =>
            handleUpdateValue("activeIndex", index)
          }
          onAdd={() => handleListItemAdd()}
          onRemove={() => handleListItemsDelete()}
          onRemoveItem={(index) => handleListItemDelete(index)}
        />
      </Box>
      <Divider sx={{ mt: 2, mb: 4 }} />
      {data.messageButtons.map((messageButton, index) => {
        if (index > 0) {
          return;
        }
        return (
          <Box key={"carousel-message-button-" + index}>
            <Box>
              <FormControl>
                <SettingFormLabel label={"더보기"} />
                <RadioGroup
                  row
                  aria-labelledby={"message_button_" + index}
                  name={"message_button_" + index}
                  value={messageButton.isLink ? "link" : "none"}
                  onChange={(e) =>
                    changeMessageButton(
                      index,
                      "isLink",
                      e.target.value === "link"
                    )
                  }
                >
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="없음"
                  />
                  <FormControlLabel
                    value="link"
                    control={<Radio />}
                    label="링크"
                  />
                </RadioGroup>
              </FormControl>
              {messageButton.isLink && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      mt: 1,
                      mb: 2,
                    }}
                  >
                    <TextField
                      sx={{ flex: 1 }}
                      size={"small"}
                      placeholder="연결할 페이지의 URL을 입력해주세요."
                      value={messageButton.link}
                      onChange={(e) =>
                        changeMessageButton(index, "link", e.target.value)
                      }
                    />
                    <Button
                      variant={"outlined"}
                      onClick={() => checkUrl(messageButton.link)}
                    >
                      URL 확인
                    </Button>
                  </Box>
                  <Box>
                    <SettingFormLabel label={"PC 전용 링크"} />
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        mt: 1,
                      }}
                    >
                      <TextField
                        sx={{ flex: 1 }}
                        size={"small"}
                        placeholder="연결할 페이지의 URL을 입력해주세요."
                        value={messageButton.pcLink}
                        onChange={(e) =>
                          changeMessageButton(index, "pcLink", e.target.value)
                        }
                      />
                      <Button
                        variant={"outlined"}
                        onClick={() => checkUrl(messageButton.link)}
                      >
                        URL 확인
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            {index + 1 < data.messageButtons.length && (
              <Divider sx={{ mt: 2, mb: 4 }} />
            )}
          </Box>
        );
      })}
    </>
  );
};

export default CarouselMessageSetting;
