export const defaultLineChartConfig = {
    type: "bar",
    height: 350,
    series: [
        {
            name: "가입수",
            data: [],
        },
    ],
    options: {
        plotOptions: {
            bar: {
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: "13px",
                            fontWeight: 900,
                        },
                    },
                    position: "top",
                },
            },
        },
        stroke: {
            width: [4],
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#008ffb"],
            },
        },
        xaxis: {
            type: "date",
        },
        yaxis: {
            labels: {
                formatter: (value) => {
                    return Math.floor(value);
                },
            },
        },
    },
};

export const defaultPieChartConfig = {
    type: "pie",
    height: 250,
    series: [],
    options: {
        chart: {
            width: 250,
            type: "pie",
        },
        colors: [
            "#62B2FD",
            "#9BDFC4",
            "#F99BAB",
            "#FFB44F",
            "#a3e635",
            "#6ee7b7",
            "#67e8f9",
            "#93c5fd",
            "#a78bfa",
            "#f472b6",
        ],
        labels: [],
        legend: {
            show: false,
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                },
            },
        ],
    },
};

export const defaultBarChartConfig = {
    type: "bar",
    height: 350,
    series: [
        {
            name: "혜택 발급수",
            type: "column",
            data: [],
        },
        {
            name: "혜택 사용수",
            type: "column",
            data: [],
        },
    ],
    options: {
        plotOptions: {
            bar: {
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: "13px",
                            fontWeight: 900,
                        },
                    },
                    position: "top",
                },
            },
        },
        fill: {
            opacity: [0.85, 0.5],
            gradient: {
                inverseColors: false,
                shade: "light",
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
            },
        },
        stroke: {
            width: [4, 4],
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#008ffb", "#00e397"],
            },
        },
        xaxis: {
            type: "date",
        },
        yaxis: [
            {
                title: {
                    text: "혜택 발급수 / 혜택 사용수",
                },
                type: "numeric",
                labels: {
                    formatter: (value) => {
                        return Math.floor(value);
                    },
                },
            },
        ],
    },
};
