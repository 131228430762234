import MobilePreview from "../../MobilePreview";

const EventLoginRoulettePreview = ({eventImage, eventButtonImage, eventBackgroundImage, loginButtonName, loginPrecautionName, loginPrecautionContent}) => {
    const API_HOST = process.env.REACT_APP_API_HOST
    return (
        <MobilePreview>
            <iframe
                className="relative h-full"
                src={`${API_HOST}/view/event?temp=true&login=true` +
                `&eventImage=${eventImage}` +
                `&eventButtonImage=${eventButtonImage}` +
                `&eventBackgroundImage=${eventBackgroundImage}` +
                `&loginButtonName=${loginButtonName}` +
                `&loginPrecautionName=${loginPrecautionName}` +
                `&loginPrecautionContent=${loginPrecautionContent}`
                }
                title="OMOPLAY TEMP"
            />
        </MobilePreview>
    )
}

export default EventLoginRoulettePreview