import InputGroup from "../InputGroup";
import NormalButton from "../../NormalButton";
import {useEffect, useState} from "react";
import {kakaoImageUpload} from "../../../api/file/file.api";
import {Button, Dropdown, RadioGroup, TextInput} from "omoplay";
import GrayButton from "../../GrayButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import BenefitTitle from "./BenefitTitle";
import BenefitMessagePreview from "../../layout/BenefitMessagePreview";
import NoImage from "../../../assets/images/no-image.svg";

const BenefitMessageSettingForm = ({ selectedBenefitIndex, benefitList, onSave, onClose }) => {
    const [currentBenefitIndex, setCurrentBenefitIndex] = useState(selectedBenefitIndex)
    const [currentBenefit, setCurrentBenefit] = useState(benefitList[currentBenefitIndex])
    const [isVariablePopup, setVariablePopup] = useState(false)
    const [messageImage, setMessageImage] = useState()
    const [messageText, setMessageText] = useState()
    const [periodType, setPeriodType] = useState()
    const [periodDays, setPeriodDays] = useState()
    const periodTypeItems = [{label: "발급일 기준", value: "issue"}, {label: "프로젝트 종료시 까지", value: "project"}]
    const [isLose, setLose] = useState(false)
    const dropdownItems = benefitList.map((benefit, index) => {
        return {
            label: (
                <div className="flex gap-8 text-mainblue-0">
                    <div className="font-bold" style={{minWidth: "20px"}}>{benefit.id ?? "-"}</div>
                    <div className="flex gap-1">
                        <div>{benefit.name ?? "꽝"}</div>
                        {
                            benefit.retailPrice ? (
                                <div>/ {benefit.retailPrice.toLocaleString()}원</div>
                            ) : <></>
                        }
                        {
                            benefit.id && (
                                <div>/ 발급일로부터 {benefit.periodDays ?? 30}일</div>
                            )
                        }
                    </div>
                </div>
            ),
            value: index
        }
    })
    let buttonName = "직원 확인하기"

    if (currentBenefit?.type === "CODE") {
        buttonName = "당첨 코드 확인"
    } else if (currentBenefit?.type === "DELIVERY") {
        buttonName = "수령 정보 입력"
    } else if (currentBenefit?.type === "LINK") {
        buttonName = "혜택 받기"
    }

    const uploadImage = (e) => {
        kakaoImageUpload(e.target.files[0]).then(({data}) => {
            if (!data?.imageUrl) {
                alert(data.message)
            } else {
                setMessageImage(data?.imageUrl)
            }
            e.target.value = null
        })
    }

    const addVariable = (text) => {
        const variable = messageText + `[${text}]`
        setMessageText(variable)
        setVariablePopup(false);
    }

    useEffect(() => {
        const currentBenefit = benefitList[currentBenefitIndex]
        setCurrentBenefit(currentBenefit)
        setMessageImage(currentBenefit.messageImage)
        setMessageText(currentBenefit.messageText)
        setLose(currentBenefit.id === null)
        if (currentBenefit.id) {
            setPeriodType(currentBenefit?.periodType ?? "issue")
            setPeriodDays(currentBenefit?.periodDays ?? 30)
        }
    }, [benefitList, currentBenefitIndex]);

    return (
        <div className="flex-col gap-2">
            <div className="flex justify-around bg-grey-0 gap-4 radius-6 p-20px" style={{ marginTop: "16px"}}>
                <div className="flex-align-center gap-4">
                    <GrayButton className="px-1.5" disabled={currentBenefitIndex <= 0} onClick={() => {
                        setCurrentBenefitIndex(currentBenefitIndex - 1)
                    }}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </GrayButton>
                    <div className="flex gap-1">
                        <div className="text-mainblue-0 font-bold">{currentBenefitIndex + 1}</div>
                        <div className="text-grey-4">/{benefitList.length}</div>
                    </div>
                    <GrayButton className="px-1.5" disabled={currentBenefitIndex >= benefitList.length - 1} onClick={() => {
                        setCurrentBenefitIndex(currentBenefitIndex + 1)
                    }}>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </GrayButton>
                </div>
                <div style={{flexBasis: "100%"}} >
                    <Dropdown key={currentBenefitIndex} variants="default" items={dropdownItems}
                              initialValue={currentBenefitIndex}
                              onChanged={(items) => {
                                  setCurrentBenefitIndex(items[0].value)
                              }} titleStyle={{ backgroundColor: "var(--mainblue-l4)",
                                  height: "56px", alignItems: "center", paddingLeft: "10px"}}
                              boxStyle={{paddingLeft: "10px"}}
                    />
                </div>
            </div>
            <div className="flex border bg-grey-0 radius-6 pl-20px pt-20px">
                <div style={{flexBasis: "50%"}}>
                    <InputGroup
                        label={"메시지 이미지"}
                        isRequired={true}
                        className="pb-6"
                    >
                        <div key={messageImage} className="flex gap-4">
                            <input
                                type="text"
                                className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                                placeholder="업로드할 파일 선택"
                                maxLength={30}
                                readOnly
                                value={messageImage}
                                required
                            />
                            <label
                                htmlFor={"prize_image"}
                                className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                            >
                                첨부
                            </label>
                            <input
                                type="file"
                                id={"prize_image"}
                                className="hidden"
                                onChange={(e) => uploadImage(e)}
                            />
                        </div>
                        <div className="text-grey-4" style={{fontSize: "12px"}}>가로 500px 이상, 최소 비율 2:1, 최대 비율 3:4 지원 포맷: png,jpeg (최대 1MB)</div>
                    </InputGroup>
                    <InputGroup
                        label={"당첨 메시지"}
                        isRequired={true}
                        className="pb-6"
                    >
                        <div className="text-grey-5">혜택의 당첨 내용을 설정해 주세요.</div>
                        <div>
                          <textarea
                              className="bg-white border border-gray-300 text-gray-900 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2 resize-none text-xs whitespace-pre-line"
                              onChange={(e) => setMessageText(e.target.value)}
                              rows={10}
                              value={messageText}
                          ></textarea>
                            {
                                isLose && (
                                    <div className="flex justify-end relative">
                                        <NormalButton
                                            className="whitespace-nowrap px-4 py-2 text-sm"
                                            onClick={() => setVariablePopup(!isVariablePopup)}
                                        >
                                            + 변수 설정
                                        </NormalButton>
                                        {isVariablePopup && (
                                            <ul className="absolute -bottom-24 right-0 bg-white rounded-lg text-sm shadow-lg border border-gray-200 text-gray-600 ">
                                                <li
                                                    className="hover:bg-gray-50 cursor-pointer py-3 px-6 rounded-t-lg"
                                                    onClick={() => addVariable("당첨 혜택")}
                                                >
                                                    당첨 혜택
                                                </li>
                                                <li
                                                    className="hover:bg-gray-50 cursor-pointer py-3 px-6 rounded-b-lg"
                                                    onClick={() => addVariable("쿠폰 사용기한")}
                                                >
                                                    쿠폰 사용기한
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                )
                            }
                        </div>
                    </InputGroup>
                    {
                        currentBenefit.id && (
                            <InputGroup
                                label={"혜택 유효기간 설정"}
                                isRequired={true}
                                className="pb-6"
                            >
                                <div key={currentBenefitIndex} className="flex-col gap-4">
                                    <RadioGroup key={periodType} items={periodTypeItems}
                                                initChecked={periodType}
                                                onSelected={(value) => {
                                                    setPeriodType(value)
                                                }} />
                                    <div className={`${periodType === "project" && "none"}`}>
                                        <div className="flex-align-center gap-2 bg-white p-20px">
                                            <div>발급일로부터</div>
                                            <div>
                                                <TextInput type="numeric"
                                                           onChange={(e) => {
                                                               setPeriodDays(e.target.value)
                                                           }}
                                                           value={periodDays}
                                                           style={{ width: "40px"}} />
                                            </div>
                                            <div>일까지</div>
                                        </div>
                                        <div className="mt-2 text-red-500" style={{fontSize: "12px"}}>
                                            혜택 유효기간은 일반적으로 1일/7일/30일 로 설정합니다.
                                        </div>
                                    </div>
                                </div>
                            </InputGroup>
                        )
                    }
                </div>
                <div className="bg-grey-0 flex-center-all flex-col gap-6" style={{flexBasis: "50%"}}>
                    <BenefitTitle name={currentBenefit?.name} retailPrice={currentBenefit?.retailPrice} />
                    <BenefitMessagePreview
                        preview={
                            <div className="relative h-full bg-repeat-round bg-contain bg-[#ABC1D1] p-4 max-h-[460px] overflow-auto">
                                <h4 className="font-medium text-gray-800 pl-2 py-2">
                                    당첨 카카오톡 안내
                                </h4>
                                <div className="flex w-full flex-col justify-center bg-white rounded-lg p-2.5">
                                    <img src={messageImage ?? NoImage} />
                                    <div className="mt-2 text-xs whitespace-pre-line">{messageText}</div>
                                    {
                                        isLose && (
                                            <button className="bg-[#F5F5F5] py-1 text-[#474A4E] text-sm font-medium rounded mt-4">
                                                {buttonName}
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    />
                    <div className="text-grey-5" style={{padding: "0 40px 40px 40px"}}>혜택의 기본 설정을 불러옵니다. 본 이벤트에 한해서 혜택 내용 및 이미지를
                        변경하실 수 있으며 설정한 내용은 혜택 기본 설정에 반영되지 않습니다.</div>
                </div>
            </div>
            <div className="flex-center-all" style={{marginTop: "12px"}}>
                <Button variants="solid" value="저장하기" disabled={!messageImage || !messageText} style={{width: "365px"}} onClick={() => {
                    onSave(currentBenefitIndex, messageImage, messageText, periodType, (periodType && !periodDays ? 30 : periodDays))
                    alert("저장 되었습니다.")
                    if (currentBenefitIndex === benefitList.length - 1) {
                        onClose()
                    } else {
                        setCurrentBenefitIndex(currentBenefitIndex + 1)
                    }
                }} />
            </div>
        </div>
    )
}

export default BenefitMessageSettingForm