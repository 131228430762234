import PreviewTemplate from "./PreviewTemplate";
import DefaultTypeContent from "./DefaultTypeContent";
import WideTypeContent from "./WideTypeContent";
import WideListTypeContent from "./WideListTypeContent";
import CarouselTypeContent from "./CarouselTypeContent";

const MessagePreview = ({ data, type, isDetail }) => {
  const renderForType = (type) => {
    if (type === "default") {
      return <DefaultTypeContent data={data} />;
    } else if (type === "wide") {
      return <WideTypeContent data={data} />;
    } else if (type === "wide_list") {
      return <WideListTypeContent data={data} />;
    } else if (type === "carousel") {
      return <CarouselTypeContent data={data} />;
    }
  };

  return (
    <PreviewTemplate isAdd={data.isAdd} isDetail={isDetail}>{renderForType(type)}</PreviewTemplate>
  );
};

export default MessagePreview;
