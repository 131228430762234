import { Box, TextField, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import SettingFormLabel from "./SettingFormLabel";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { messageFileUpload } from "../../api/file/file.api";
import NormalButton from "../NormalButton";
import LabelNormalButton from "../LabelNormalButton";

const SettingItemForm = ({
  type,
  onChange,
  onDelete,
  id,
  data,
  isDeletable = false,
  sx,
  isMain = false,
}) => {
  const [image, setImage] = useState(data.image);
  const [content, setContent] = useState(data.content);
  const [link, setLink] = useState(data.link);

  useEffect(() => {
    onChange({
      image,
      content,
      link,
    });
  }, [image, content, link]);

  const uploadImage = (e) => {
    messageFileUpload(
      type,
      e.target.files[0],
      (res) => {
        if (res.status === 200) {
          const data = res.data;
          if (data.code === "0") {
            setImage(res.data.imageUrl);
          } else {
            alert(data.message);
          }
        } else {
          alert(
            res.status +
              "에러가 발생했습니다. 헤드리스 담당자에게 문의 해주세요."
          );
        }
      },
      (error) => {
        alert(error);
      }
    );
  };
  const checkUrl = (url) => {
    window.open(url);
  };

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "#F2F4F7",
        borderRadius: "6px",
        ...sx,
      }}
    >
      <Box>
        <SettingFormLabel
          label={"이미지 업로드"}
          isRequired={true}
          sx={{ fontSize: "14px" }}
          actionButton={
            isDeletable && (
              <IconButton onClick={() => onDelete()}>
                <DeleteOutlineIcon />
              </IconButton>
            )
          }
        />
        <Box sx={{ display: "flex", gap: "8px", mt: 1 }}>
          <input
            accept="image/jpg, image/jpeg, image/png"
            style={{ display: "none" }}
            id={id}
            type="file"
            onChange={(e) => uploadImage(e)}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            placeholder="이미지 파일을 업로드 해주세요."
            helperText={
              (isMain
                ? "* 권장사이즈 : 800 x 400px"
                : "* 권장사이즈 : 400 x 400px") +
              " / 지원파일 : JPG, JPEG, PNG (최대 2MB)"
            }
            size={"small"}
            value={image || ""}
          />
          <LabelNormalButton
            className={"whitespace-nowrap py-2 px-4 text-sm h-fit"}
            htmlFor={id}
            sx={{ height: "40px", whiteSpace: "nowrap" }}
          >
            업로드 하기
          </LabelNormalButton>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <SettingFormLabel
          label={"내용"}
          isRequired={true}
          sx={{ fontSize: "14px" }}
        />
        <Box sx={{ mt: 1 }}>
          <div className="relative" style={{backgroundColor: "#FFF"}}>
            <TextField
              inputProps={{ maxLength: 25 }}
              multiline
              rows={2}
              fullWidth
              value={content || ""}
              onChange={(e) => setContent(e.target.value)}
            />
            <p className="absolute right-2 bottom-2 text-xs text-gray-400">
              {data.content?.length || 0}/25자
            </p>
          </div>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <SettingFormLabel
          label={"링크"}
          isRequired={true}
          sx={{ fontSize: "14px" }}
        />
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            mt: 1,
          }}
        >
          <TextField
            sx={{ flex: 1 }}
            size={"small"}
            placeholder="연결할 페이지의 URL을 입력해주세요."
            value={link || ""}
            onChange={(e) => setLink(e.target.value)}
          />
          <NormalButton
            variant={"outlined"}
            onClick={() => checkUrl(link)}
            className={"whitespace-nowrap py-2 px-4 text-sm"}
          >
            URL 확인
          </NormalButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingItemForm;
