import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import SettingsLayout from "../components/layout/SettingsLayout";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Popup from "../components/Popup";
import SuspensePopup from "../components/SuspensePopup";
import GrayButton from "../components/GrayButton";
import ActiveButton from "../components/ActiveButton";
import { useResetRecoilState } from "recoil";
import { couponGroupState } from "../store/state/couponGroup";
import EditCouponContent from "../components/page/EditCouponContent";
import { getCoupon, updateCoupon } from "../api/event/coupon.api";

const EditCoupon = () => {
  const tabs = [
    { label: "당첨 카톡", value: 1 },
    { label: "쿠폰 조회", value: 2 },
  ];
  const [query] = useSearchParams();
  const [data, setData] = useState();
  const [tab, setTab] = useState(tabs[0].value);
  const [isValidatePopup, setValidatePopup] = useState(false);
  const [isValidated, setValidated] = useState(false);
  const [isConfirmedPopup, setConfirmedPopup] = useState(false);
  const [editId, setEditId] = useState();
  const [originalData, setOriginalData] = useState();
  const reset = useResetRecoilState(couponGroupState);

  const handleTemporarySave = () => {
    setValidated(true);
    if (editId != null) {
      handleUpdate(data, true);
    } else {
      handleCreate(data, true);
    }
  };

  const handleConfirm = () => {
    setValidated(true);
    if (!validateData(data)) {
      setValidatePopup(true);
      return false;
    } else {
      if (editId != null) {
        handleUpdate(data)
      } else {
        handleCreate(data);
      }
    }
  };

  const validateData = (data) => {
    if (
      !data.notificationImage ||
      !data.notificationMessage ||
      !data.couponImage
    ) {
      return false;
    }
    return true;
  };

  const handleCreate = async (data, isTemporary = false) => {};

  const handleUpdate = async (data, isTemporary = false) => {
    const id = query.get("id");
    const res = await updateCoupon(id, data);
    if (res) {
      setConfirmedPopup(isTemporary ? "temporary" : "save");
    } else {
      alert("오류가 발생했습니다. 잠시후 다시 시도해주세요.");
    }
  };

  const handleConfirmed = () => {
    reset();
    setConfirmedPopup(false);
    window.location.href = ("/coupon")
  };

  const handleGetCouponData = useCallback(async (id) => {
    const res = await getCoupon(id);
    if (res.data) {
      let updateValue = res.data;
      setOriginalData(updateValue);
      setData(updateValue);
      setEditId(id);
    }
  }, []);

  useEffect(() => {
    const id = query.get("id");
    if (id) {
      handleGetCouponData(id);
    }
  }, [handleGetCouponData, query]);

  return (
    <SettingsLayout
      title={"쿠폰 추가하기"}
      icon={faCommentDots}
      tabs={tabs}
      isHeader={false}
      activeTab={tab}
      onChange={(value) => setTab(value)}
      onTemporarySave={handleTemporarySave}
      onConfirm={handleConfirm}
      onClose={() => window.location.href = ("/coupon")}
    >
      <EditCouponContent
        editId={editId}
        tabs={tabs}
        activeTab={tab}
        changeTab={setTab}
        data={data}
        onChange={setData}
        originalData={originalData}
        isValidated={isValidated}
      />
      {isValidatePopup && (
        <SuspensePopup
          isEditPopup={true}
          title={"확인해 주세요"}
          size={"w-full max-w-xl"}
          onClose={() => setValidatePopup(false)}
        >
          <div className="p-4">
            <p>아래 내용이 작성되지 않아, 임시 저장만 가능합니다.</p>
            <div className="rounded bg-red-50 text-red-500 p-4 mt-3">
              <ul className="text-sm flex-col w-full gap-2">
                {!data.notificationImage && (
                  <li>
                    [1. 당첨 카톡] 의 ‘당첨 이미지'가 설정되지 않았습니다.
                  </li>
                )}
                {!data.notificationMessage && (
                  <li> [1. 당첨 카톡] 의 ‘당첨 안내'가 설정되지 않았습니다.</li>
                )}
                {!data.couponImage && (
                  <li>
                    [2. 쿠폰 조회] 의 ‘당첨 이미지'가 설정되지 않았습니다.
                  </li>
                )}
              </ul>
            </div>
            <div className="w-full justify-end flex gap-4 mt-4">
              <GrayButton
                className="py-2 px-4 text-sm"
                onClick={() => setValidatePopup(false)}
              >
                다시 설정
              </GrayButton>
              <ActiveButton
                className="py-2 px-4 text-sm"
                onClick={() => handleTemporarySave()}
              >
                임시 저장
              </ActiveButton>
            </div>
          </div>
        </SuspensePopup>
      )}
      {isConfirmedPopup && (
        <Popup
          title={
            (isConfirmedPopup === "temporary" ? "임시 저장" : "쿠폰 설정") +
            "이 완료되었습니다"
          }
          buttons={[
            {
              label: "확인",
              type: "active",
              onClick: () => {
                handleConfirmed();
              },
            },
          ]}
        />
      )}
    </SettingsLayout>
  );
};
export default EditCoupon;
