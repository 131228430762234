import {
  Box,
  Divider,
  Typography,
  TextField,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useEffect, useState } from "react";
import { Add, CloseOutlined } from "@mui/icons-material";
import SettingFormLabel from "./SettingFormLabel";
import TextSettingInput from "./TextSettingInput";
import { cloneDeep } from "lodash";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { messageFileUpload } from "../../api/file/file.api";
import NormalButton from "../NormalButton";
import ActiveButton from "../ActiveButton";
import SuspensePopup from "../SuspensePopup";
import CouponConnectionForm from "./CouponConnectionForm";
const CarouselForm = ({
  type,
  activeIndex,
  onChangeActiveIndex,
  items,
  onAdd,
  onUpdate,
  onRemoveItem,
  onRemove,
}) => {
  const [activeItem, setActiveItem] = useState({ ...items[activeIndex] });
  const [openDialog, setOpenDialog] = useState(false);
  const [isOpenConnectionPopup, setOpenConnectionPopup] = useState(null);

  const changeMessageButton = (index, key, value) => {
    const updateValue = [...items];
    updateValue[activeIndex]["messageButtons"][index][key] = value;
    setActiveItem({ ...updateValue[activeIndex] });
    onUpdate([...updateValue]);
  };

  const updateItem = (key, value) => {
    const updateValue = [...items];
    updateValue[activeIndex][key] = value;
    setActiveItem({ ...updateValue[activeIndex] });
    onUpdate(updateValue);
  };

  const updateItemWithIndex = (index, key, value) => {
    const updateValue = cloneDeep(items);
    updateValue[index][key] = value;
    setActiveItem({ ...updateValue[index] });
    onUpdate(updateValue);
  };

  const handleRemoveItems = () => {
    onChangeActiveIndex(0);
    onRemove();
    setOpenDialog(false);
  };

  const checkUrl = (url) => {
    window.open(url);
  };

  const uploadImage = (index, e) => {
    messageFileUpload(
      type,
      e.target.files[0],
      (res) => {
        if (res.status === 200) {
          const data = res.data;
          if (data.code === "0") {
            updateItemWithIndex(index, "image", res.data.imageUrl);
          } else {
            console.log(data);
            alert(data.message);
          }
        } else {
          console.error(res.data);
          alert(
            res.status +
              "에러가 발생했습니다. 헤드리스 담당자에게 문의 해주세요."
          );
        }
      },
      (error) => {
        alert(error);
      }
    );
  };

  useEffect(() => {
    if (items && items.length < 6) {
      const isImageFull =
        items.filter((item) => item.image == null).length == 0;
      if (isImageFull) {
        onAdd();
      }
    }
  }, [items]);

  useEffect(() => {
    if (items.length > 0) {
      setActiveItem(items[activeIndex]);
    }
  }, [activeIndex, items]);
  const handleMappingCouponUpdate = (index, coupon) => {
    changeMessageButton(index, "coupon", coupon);
    setOpenConnectionPopup(null);
  };
  return (
    <Box sx={{ p: 2, backgroundColor: "#F2F4F7", borderRadius: "6px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={"12px"} color={"grey5"}>
          가로 500px 이상, 최소 비율 2:1, 최대 비율 3:4 지원 포맷: png,jpeg (최대 2MB)
        </Typography>
        <NormalButton
          startIcon={<DeleteOutlineIcon />}
          className="text-sm py-1 px-2"
          onClick={() => setOpenDialog(true)}
        >
          전체 삭제
        </NormalButton>
      </Box>
      <Box className="carousel-header-wrap">
        {items.map((item, index) => {
          const isActive = activeIndex === index;
          const id = "carousel-item-image-" + index;
          return (
            <Box
              key={"carousel-header-item-" + index}
              className={"clickable " + (isActive ? "active" : "")}
              onClick={() => onChangeActiveIndex(index)}
            >
              {isActive && (
                <Box sx={{ textAlign: "right" }}>
                  <CloseOutlined
                    fontSize="12px"
                    onClick={() => onRemoveItem(index)}
                  />
                </Box>
              )}
              <input
                accept="image/jpg, image/jpeg, image/png"
                style={{ display: "none" }}
                id={id}
                type="file"
                onChange={(e) => uploadImage(index, e)}
              />

              {item.image ? (
                <Box
                  className="carousel-image-box uploaded"
                  sx={{
                    backgroundImage: `url(${item.image})`,
                  }}
                >
                  {isActive && (
                    <label htmlFor={id}>
                      <ModeEditOutlineOutlinedIcon
                        sx={{
                          color: "white",
                          opacity: 0.7,
                        }}
                      />
                    </label>
                  )}
                </Box>
              ) : (
                <Box className="carousel-image-box clickable">
                  <label htmlFor={id}>
                    <Add />
                  </label>
                </Box>
              )}
            </Box>
          );
        })}
        {items.filter(
          (item) =>
            item.image != null && item.content != null && item.title != null
        ).length < 2 && (
          <Box className="text-red-500 text-sm mb-4">* 2개는 필수입니다</Box>
        )}
        <Box className="carousel-background-mask"></Box>
      </Box>
      <Box className="carousel-content">
        <Box>
          <div className="relative">
            <TextSettingInput
              inputProps={{ maxLength: 20 }}
              label={"타이틀"}
              isRequired={true}
              size={"small"}
              value={activeItem?.title || ""}
              placeholder={"타이틀을 작성해주세요"}
              onChange={(e) =>
                e.target.value.length <= 20 &&
                updateItem("title", e.target.value)
              }
            />
            <p className="absolute right-2 bottom-2.5 text-xs text-gray-400">
              {activeItem?.title?.length || 0}/20자
            </p>
          </div>
        </Box>
        <Divider sx={{ mt: 2, mb: 4 }} />
        <Box>
          <SettingFormLabel label={"내용"} isRequired={true} />
          <div className="relative">
            <TextField
              inputProps={{ maxLength: 180 }}
              multiline
              rows={8}
              fullWidth
              placeholder="메시지 내용을 작성해 주세요"
              value={activeItem?.content || ""}
              onChange={(e) =>
                e.target.value.length <= 180 &&
                updateItem("content", e.target.value)
              }
            />
            <p className="absolute right-2 bottom-8 text-xs text-gray-400">
              {activeItem?.content?.length || 0}/180자
            </p>
            <p className="text-[#4548DE] text-xs mt-2">
              * 줄바꿈은 2개를 초과하여 사용할 수 없습니다.
            </p>
          </div>
        </Box>
        <Divider sx={{ mt: 2, mb: 4 }} />
        {activeItem?.messageButtons?.map((messageButton, index) => {
          return (
            <Box key={"carousel-form-message-button-" + activeIndex + index}>
              <Box>
                <FormControl>
                  <SettingFormLabel
                    isRequired={messageButton.isRequire}
                    label={"메시지 버튼"}
                  />
                  <RadioGroup
                    row
                    aria-labelledby={"message_button_" + index}
                    name={"message_button_" + index}
                    value={messageButton.isLink || "none"}
                    onChange={(e) =>
                      changeMessageButton(index, "isLink", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="none"
                      control={<Radio />}
                      label="없음"
                    />
                    <FormControlLabel
                      value="link"
                      control={<Radio />}
                      label="링크"
                    />
                    {<FormControlLabel
                      value="coupon"
                      control={<Radio />}
                      label="쿠폰"
                    />}
                  </RadioGroup>
                </FormControl>
                {messageButton.isLink === "link" && (
                  <Box>
                    <div className="relative">
                      <TextField
                        size={"small"}
                        fullWidth
                        placeholder="버튼명을 입력해 주세요."
                        inputProps={{ maxLength: 8 }}
                        value={messageButton.name || ""}
                        onChange={(e) =>
                          e.target.value.length <= 8 &&
                          changeMessageButton(index, "name", e.target.value)
                        }
                      />
                      <p className="absolute right-2 bottom-3 text-xs text-gray-400">
                        {messageButton.name?.length || 0}/8자
                      </p>
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        mt: 1,
                        mb: 2,
                      }}
                    >
                      <TextField
                        sx={{ flex: 1 }}
                        size={"small"}
                        placeholder="연결할 페이지의 URL을 입력해주세요."
                        value={messageButton.link || ""}
                        onChange={(e) =>
                          changeMessageButton(index, "link", e.target.value)
                        }
                      />
                      <NormalButton
                        variant={"outlined"}
                        onClick={() => checkUrl(messageButton.link)}
                      >
                        URL 확인
                      </NormalButton>
                    </Box>
                    <Box>
                      <SettingFormLabel label={"PC 전용 링크"} />
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          mt: 1,
                        }}
                      >
                        <TextField
                          sx={{ flex: 1 }}
                          size={"small"}
                          placeholder="연결할 페이지의 URL을 입력해주세요."
                          value={messageButton.pcLink || ""}
                          onChange={(e) =>
                            changeMessageButton(index, "pcLink", e.target.value)
                          }
                        />
                        <NormalButton
                          variant={"outlined"}
                          onClick={() => checkUrl(messageButton.link)}
                        >
                          URL 확인
                        </NormalButton>
                      </Box>
                    </Box>
                  </Box>
                )}
                {messageButton.isLink === "coupon" && (
                  <Box>
                    <TextField
                      size={"small"}
                      fullWidth
                      placeholder="쿠폰명을 입력해 주세요."
                      value={messageButton.name}
                      onChange={(e) =>
                        changeMessageButton(index, "name", e.target.value)
                      }
                    />
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        mt: 1,
                      }}
                    >
                      <TextField
                        sx={{ flex: 1 }}
                        size={"small"}
                        placeholder="연결할 쿠폰을 선택해주세요"
                        value={messageButton.coupon?.name}
                        disabled={true}
                        readOnly
                      />
                      <NormalButton
                        variant={"outlined"}
                        onClick={() => setOpenConnectionPopup(index)}
                      >
                        쿠폰 연결하기
                      </NormalButton>
                    </Box>
                  </Box>
                )}
              </Box>
              {index + 1 < activeItem?.messageButtons.length && (
                <Divider sx={{ mt: 2, mb: 4 }} />
              )}
            </Box>
          );
        })}
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          정말 모두 삭제하시겠어요?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ‘전체 삭제’를 누르시면 만들어 놓은 모든 캐러셀 정보가 삭제돼요.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <NormalButton onClick={() => setOpenDialog(false)} variant="outlined">
            아니오
          </NormalButton>
          <ActiveButton
            autoFocus
            variant="contained"
            onClick={() => handleRemoveItems()}
          >
            전체 삭제
          </ActiveButton>
        </DialogActions>
      </Dialog>
      {isOpenConnectionPopup != null && (
        <SuspensePopup
          isEditPopup={true}
          title={"쿠폰 연결하기"}
          size={"w-[1200px]"}
          onClose={() => setOpenConnectionPopup(null)}
        >
          <CouponConnectionForm
            onClick={(coupon) =>
              handleMappingCouponUpdate(isOpenConnectionPopup, coupon)
            }
            used={true}
          />
        </SuspensePopup>
      )}
    </Box>
  );
};

export default CarouselForm;
