import { Box, Typography, Button } from "@mui/material";
const WideListTypeContent = ({ data }) => {
  const handleImageClick = (data) => {
    if (data?.link) {
      window.open(data.link);
    }
  };
  return (
    <Box
      sx={{ width: "246px" }}
      className="message-content default-message-content"
    >
      <Typography textAlign={"center"} fontWeight={"600"} color="grey7">
        {data.title || "타이틀"}
      </Typography>
      <Box
        className="preview-image-wrap clickable wide-list"
        sx={{
          width: "100%",
          height: "115px",
          backgroundImage: data.mainItem?.image
            ? `url(${data.mainItem?.image})`
            : "linear-gradient( 0deg, rgb(145 143 143), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) );",
        }}
        onClick={() => handleImageClick(data.mainItem)}
      >
        <Typography sx={{ color: "#FFF", p: 2 }} fontSize={"14px"}>
          {data.mainItem?.content || ""}
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        {data.listItems &&
          data.listItems.map((listItem, index) => {
            return (
              <Box
                key={"wide-list-type-content" + index}
                sx={{
                  display: "flex",
                  gap: 1,
                  mt: 1,
                  alignItems: "center",
                }}
              >
                <Box
                  className="preview-list-wrap clickable"
                  onClick={() => handleImageClick(listItem)}
                  sx={{
                    backgroundImage: `url(${listItem.image})`,
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "174px",
                  }}
                >
                  <Typography
                    sx={{ wordBreak: "break-word", fontSize: "14px" }}
                  >
                    {listItem.content}
                  </Typography>
                </Box>
              </Box>
            );
          })}
      </Box>
      {
          data.messageButtons && (
              <div className="flex">
                {
                  data.messageButtons
                      .filter((button) => button.isLink === "link" || button.isLink === "coupon")
                      .map((button, index) => {
                        return (
                            <Button
                                className="preview-button"
                                variant="outlined"
                                color={"grey7"}
                                onClick={() => window.open(button.link)}
                                sx={{ mr: index === 0 ? 1 : 0, mt: 2 }}
                            >
                              {button.name || "메시지 버튼"}
                            </Button>
                        );
                      })
                }
              </div>
          )
      }
    </Box>
  );
};

export default WideListTypeContent;
