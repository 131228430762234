export const defaultNotificationMessage = ` ✨ 000 이벤트 ✨
[당첨 혜택] 이 당첨 되셨어요! 🎉
결제하실 때 아래 ‘바코드 확인’을 클릭하여 사용해 주세요!

▶ 기존 주문 변경 및 취소 후 사용이 불가능하오니 주문 완료 시 다음 번에 사용해 주세요
▶ 쿠폰 사용 기한은 [쿠폰 사용기한] 까지 입니다`;

export const directNotificationMessage = ` ✨ 000 이벤트 ✨
[당첨 혜택] 이 당첨 되셨어요! 🎉
결제하실 때 아래 ‘직원 확인하기’를 클릭하여 사용해 주세요!

▶ 기존 주문 변경 및 취소 후 사용이 불가능하오니 주문 완료 시 다음 번에 사용해 주세요
▶ 쿠폰 사용 기한은 [쿠폰 사용기한] 까지 입니다`;
