import {
  Box,
  Divider,
  Typography,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import SettingFormLabel from "../../../form/SettingFormLabel";
import { messageFileUpload } from "../../../../api/file/file.api";
import NormalButton from "../../../NormalButton";
import LabelNormalButton from "../../../LabelNormalButton";
import SuspensePopup from "../../../SuspensePopup";
import CouponConnectionForm from "../../../form/CouponConnectionForm";

const DefaultMessageSetting = ({ type, data, onChange }) => {
  const [isOpenConnectionPopup, setOpenConnectionPopup] = useState(null);
  const changeMessageButton = (index, key, value) => {
    const updateValue = [...data.messageButtons];
    updateValue[index][key] = value;
    handleUpdateValue("messageButtons", updateValue);
  };
  const checkUrl = (url) => {
    window.open(url);
  };
  const uploadImage = (e) => {
    messageFileUpload(
      type,
      e.target.files[0],
      (res) => {
        if (res.status === 200) {
          const data = res.data;
          if (data.code === "0") {
            handleUpdateValue("image", res.data.imageUrl);
          } else {
            alert(data.message);
          }
        } else {
          console.error(res.data);
          alert(
            res.status +
              "에러가 발생했습니다. 헤드리스 담당자에게 문의 해주세요."
          );
        }
      },
      (error) => {
        alert(error);
      }
    );
  };

  useEffect(() => {
    if (data.decorate === "text") {
      handleUpdateValue("image", null);
    }
  }, [data.decorate]);

  const handleUpdateValue = (key, value) => {
    onChange({ ...data, [key]: value });
  };

  const handleMappingCouponUpdate = (index, coupon) => {
    changeMessageButton(index, "coupon", coupon);
    setOpenConnectionPopup(null);
  };

  return (
    <>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 2 }}>
        기본 텍스트형 작성하기
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Box>
        <FormControl>
          <SettingFormLabel label={"광고 표기 여부"} />
          <RadioGroup
            row
            aria-labelledby="is_add"
            name="is_add"
            value={data.isAdd ? "add" : "info"}
            onChange={(e) =>
              handleUpdateValue("isAdd", e.target.value === "add")
            }
          >
            <FormControlLabel value="add" control={<Radio />} label="광고성" />
            <FormControlLabel value="info" control={<Radio />} label="정보성" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Divider sx={{ mt: 2, mb: 4 }} />
      <Box>
        <FormControl>
          <SettingFormLabel label={"메시지 꾸미기"} />
          <RadioGroup
            row
            aria-labelledby="decorate_message"
            name="decorate_message"
            value={(data.decorate === "image" || data.image) ? "image" : "text"}
            onChange={(e) => handleUpdateValue("decorate", e.target.value)}
          >
            <FormControlLabel
              value="text"
              control={<Radio />}
              label="없음(텍스트만)"
            />
            <FormControlLabel
              value="image"
              control={<Radio />}
              label="이미지"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {(data.decorate === "image" || data.image) && (
        <Box sx={{ display: "flex", gap: "8px" }}>
          <input
            accept="image/jpg, image/jpeg, image/png"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={(e) => uploadImage(e)}
          />
          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            placeholder="이미지 파일을 업로드 해주세요."
            helperText="* 가로 500px 이상, 최소 비율 2:1, 최대 비율 3:4 지원 포맷: png,jpeg (최대 1MB)"
            size={"small"}
            value={data.image || ""}
          />
          <LabelNormalButton
            htmlFor="raised-button-file"
            className="whitespace-nowrap py-2 px-4 text-sm h-fit"
          >
            업로드 하기
          </LabelNormalButton>
        </Box>
      )}
      <Divider sx={{ mt: 2, mb: 4 }} />
      <Box>
        <SettingFormLabel label={"내용"} isRequired={true} />
        <div className="relative">
          <TextField
            inputProps={{ maxLength: 400 }}
            multiline
            rows={8}
            fullWidth
            helperText="수신거부 | 홈 > 채널차단"
            value={data.content || ""}
            onChange={(e) =>
              e.target.value.length <= 400 &&
              handleUpdateValue("content", e.target.value)
            }
          />
          <p className="absolute right-2 bottom-8 text-xs text-gray-400">
            {data.content?.length || 0}/400자
          </p>
        </div>
      </Box>
      <Divider sx={{ mt: 3, mb: 4 }} />
      {data.messageButtons.map((messageButton, index) => {
        return (
          <Box key={"default-message-button-" + index}>
            <Box>
              <FormControl>
                <SettingFormLabel label={`메시지 버튼 ${index + 1}`} />
                <RadioGroup
                  row
                  aria-labelledby={"message_button_" + index}
                  name={"message_button_" + index}
                  value={messageButton.isLink || "none"}
                  onChange={(e) =>
                    changeMessageButton(index, "isLink", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="없음"
                  />
                  <FormControlLabel
                    value="link"
                    control={<Radio />}
                    label="링크"
                  />
                  { <FormControlLabel
                    value="coupon"
                    control={<Radio />}
                    label="쿠폰"
                  /> }
                </RadioGroup>
              </FormControl>
              {messageButton.isLink === "link" && (
                <Box>
                  <div className="relative">
                    <TextField
                      size={"small"}
                      fullWidth
                      placeholder="버튼명을 입력해 주세요."
                      inputProps={{ maxLength: 8 }}
                      value={messageButton.name}
                      onChange={(e) =>
                        e.target.value.length <= 8 &&
                        changeMessageButton(index, "name", e.target.value)
                      }
                    />
                    <p className="absolute right-2 bottom-3 text-xs text-gray-400">
                      {messageButton.name?.length || 0}/8자
                    </p>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      mt: 1,
                    }}
                  >
                    <TextField
                      sx={{ flex: 1 }}
                      size={"small"}
                      placeholder="연결할 페이지의 URL을 입력해주세요."
                      value={messageButton.link}
                      onChange={(e) =>
                        changeMessageButton(index, "link", e.target.value)
                      }
                    />
                    <NormalButton
                      variant={"outlined"}
                      onClick={() => checkUrl(messageButton.link)}
                    >
                      URL 확인
                    </NormalButton>
                  </Box>
                </Box>
              )}
              {messageButton.isLink === "coupon" && (
                <Box>
                  <TextField
                    size={"small"}
                    fullWidth
                    placeholder="쿠폰명을 입력해 주세요."
                    value={messageButton.name}
                    onChange={(e) =>
                      changeMessageButton(index, "name", e.target.value)
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      mt: 1,
                    }}
                  >
                    <TextField
                      sx={{ flex: 1 }}
                      size={"small"}
                      placeholder="연결할 쿠폰을 선택해주세요"
                      value={messageButton.coupon?.name}
                      disabled={true}
                      readOnly
                    />
                    <NormalButton
                      variant={"outlined"}
                      onClick={() => setOpenConnectionPopup(index)}
                    >
                      쿠폰 연결하기
                    </NormalButton>
                  </Box>
                </Box>
              )}
            </Box>
            {index + 1 < data.messageButtons.length && (
              <Divider sx={{ mt: 2, mb: 4 }} />
            )}
          </Box>
        );
      })}
      {isOpenConnectionPopup != null && (
        <SuspensePopup
          isEditPopup={true}
          title={"쿠폰 연결하기"}
          size={"w-[1200px]"}
          onClose={() => setOpenConnectionPopup(null)}
        >
          <CouponConnectionForm
            onClick={(coupon) =>
              handleMappingCouponUpdate(isOpenConnectionPopup, coupon)
            }
            used={true}
          />
        </SuspensePopup>
      )}
    </>
  );
};

export default DefaultMessageSetting;
