import {fetchApi} from "../fetch";
import {API_URL} from "../../constants/axios";

export const getCustomersStatistics = async (startDate, endDate, projectId) => {
    const res = await fetchApi.get(projectId ?
        `${API_URL.STATISTICS_CUSTOMERS}?projectId=${projectId}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`
        : `${API_URL.STATISTICS_CUSTOMERS}${startDate ? `?startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`)
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}

export const getSpotStatistics = async (projectId, spotId, startDate, endDate) => {
    const res = await fetchApi.get(
        `${API_URL.STATISTICS_SPOT}?projectId=${projectId}${spotId ? `&spotId=${spotId}` : ''}&startDate=${startDate}&endDate=${endDate}`);
    if (res.status === 200) {
        return res.data;
    } else {
        return [];
    }
}