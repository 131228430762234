import {
  Box,
  Divider,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import SettingFormLabel from "../../../form/SettingFormLabel";
import { messageFileUpload } from "../../../../api/file/file.api";
import NormalButton from "../../../NormalButton";
import LabelNormalButton from "../../../LabelNormalButton";
import SuspensePopup from "../../../SuspensePopup";
import CouponConnectionForm from "../../../form/CouponConnectionForm";

const WideMessageSetting = ({ type, data, onChange }) => {
  const [isAdd, setIsAdd] = useState(data.isAdd);
  const [image, setImage] = useState(data.image);
  const [content, setContent] = useState(data.content || "");
  const [link, setLink] = useState(data.link);
  const [messageButtons, setMessageButtons] = useState(
    data.messageButtons || [{ isLink: 'none' }]
  );
  const [isOpenConnectionPopup, setOpenConnectionPopup] = useState(null);

  const changeMessageButton = (index, key, value) => {
    const updateValue = [...messageButtons];
    updateValue[index][key] = value;
    setMessageButtons(updateValue);
  };
  const checkUrl = (url) => {
    window.open(url);
  };

  const handleChange = () => {
    onChange({
      isAdd,
      image,
      content,
      link,
      messageButtons,
    });
  };

  const uploadImage = (e) => {
    messageFileUpload(
      type,
      e.target.files[0],
      (res) => {
        if (res.status === 200) {
          const data = res.data;
          if (data.code === "0") {
            setImage(res.data.imageUrl);
          } else {
            alert(data.message);
          }
        } else {
          console.error(res.data);
          alert(
            res.status +
              "에러가 발생했습니다. 헤드리스 담당자에게 문의 해주세요."
          );
        }
      },
      (error) => {
        alert(error);
      }
    );
  };

  useEffect(() => {
    handleChange();
  }, [isAdd, image, content, link, messageButtons]);

  const handleMappingCouponUpdate = (index, coupon) => {
    changeMessageButton(index, "coupon", coupon);
    setOpenConnectionPopup(null);
  };
  return (
    <>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 2 }}>
        와이드 이미지형 작성하기
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Box>
        <FormControl>
          <SettingFormLabel label={"광고 표기 여부"} />
          <RadioGroup
            row
            aria-labelledby="is_add"
            name="is_add"
            value={isAdd ? "add" : "info"}
            onChange={(e) => setIsAdd(e.target.value === "add")}
          >
            <FormControlLabel value="add" control={<Radio />} label="광고성" />
            <FormControlLabel value="info" control={<Radio />} label="정보성" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Divider sx={{ mt: 2, mb: 4 }} />
      <Box>
        <SettingFormLabel label={"이미지 업로드"} isRequired={true} />
        <Box sx={{ display: "flex", gap: "8px" }}>
          <input
            accept="image/jpg, image/jpeg, image/png"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={(e) => uploadImage(e)}
          />

          <TextField
            inputProps={{ readOnly: true }}
            fullWidth
            placeholder="이미지 파일을 업로드 해주세요."
            helperText="* 고정 : 800 x 600px / 지원파일 : JPG, JPEG, PNG (최대 2MB)"
            size={"small"}
            value={image || ""}
          />
          <LabelNormalButton
            htmlFor="raised-button-file"
            className="whitespace-nowrap py-2 px-4 text-sm h-fit"
          >
            업로드 하기
          </LabelNormalButton>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <SettingFormLabel label={"링크"} />
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            mt: 1,
          }}
        >
          <TextField
            sx={{ flex: 1 }}
            size={"small"}
            value={link || ""}
            placeholder="연결할 페이지의 URL을 입력해주세요."
            onChange={(e) => setLink(e.target.value)}
          />
          <NormalButton onClick={() => checkUrl(link)}>URL 확인</NormalButton>
        </Box>
      </Box>
      <Divider sx={{ mt: 2, mb: 4 }} />
      <Box>
        <SettingFormLabel label={"내용"} isRequired={true} />
        <div className="relative">
          <TextField
            inputProps={{ maxLength: 76 }}
            multiline
            fullWidth
            helperText="수신거부 | 홈 > 채널차단"
            value={content || ""}
            placeholder="메시지 내용을 작성해 주세요"
            rows={8}
            onChange={(e) =>
              e.target.value.length <= 76 && setContent(e.target.value)
            }
          />
          <p className="absolute right-2 bottom-12 text-xs text-gray-400">
            {content.length || 0}/76자
          </p>
          <p className="text-[#4548DE] text-xs ml-3">
            * 줄바꿈은 1개를 초과하여 사용할 수 없습니다.
          </p>
        </div>
      </Box>
      <Divider sx={{ mt: 3, mb: 4 }} />
      {messageButtons.map((messageButton, index) => {
        return (
          <Box key={"wide-message-button-" + index}>
            <Box>
              <FormControl>
                <SettingFormLabel
                  label={
                    messageButtons.length === 1
                      ? "메시지 버튼"
                      : "메시지 버튼 " + (index + 1)
                  }
                />
                <RadioGroup
                  row
                  aria-labelledby={"message_button_" + index}
                  name={"message_button_" + index}
                  value={messageButton.isLink || "none"}
                  onChange={(e) =>
                    changeMessageButton(index, "isLink", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="없음"
                  />
                  <FormControlLabel
                    value="link"
                    control={<Radio />}
                    label="링크"
                  />
                  <FormControlLabel
                    value="coupon"
                    control={<Radio />}
                    label="쿠폰"
                  />
                </RadioGroup>
              </FormControl>
              {messageButton.isLink === "link" && (
                <Box>
                  <div className="relative">
                    <TextField
                      size={"small"}
                      fullWidth
                      placeholder="버튼명을 입력해 주세요."
                      inputProps={{ maxLength: 8 }}
                      value={messageButton.name}
                      onChange={(e) =>
                        e.target.value.length <= 8 &&
                        changeMessageButton(index, "name", e.target.value)
                      }
                    />
                    <p className="absolute right-2 bottom-3 text-xs text-gray-400">
                      {messageButton.name?.length || 0}/8자
                    </p>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      mt: 1,
                    }}
                  >
                    <TextField
                      sx={{ flex: 1, mb: 2 }}
                      size={"small"}
                      placeholder="연결할 페이지의 URL을 입력해주세요."
                      value={messageButton.link || ""}
                      onChange={(e) =>
                        changeMessageButton(index, "link", e.target.value)
                      }
                    />
                    <NormalButton
                      className="h-[43px] px-4"
                      onClick={() => checkUrl(messageButton.link)}
                    >
                      URL 확인
                    </NormalButton>
                  </Box>
                  <Box>
                    <SettingFormLabel label={"PC 전용 링크"} />
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        mt: 1,
                      }}
                    >
                      <TextField
                        sx={{ flex: 1 }}
                        size={"small"}
                        placeholder="연결할 페이지의 URL을 입력해주세요."
                        value={messageButton.pcLink || ""}
                        onChange={(e) =>
                          changeMessageButton(index, "pcLink", e.target.value)
                        }
                      />
                      <NormalButton
                        variant={"outlined"}
                        onClick={() => checkUrl(messageButton.link)}
                      >
                        URL 확인
                      </NormalButton>
                    </Box>
                  </Box>
                </Box>
              )}
              {messageButton.isLink === "coupon" && (
                <Box>
                  <TextField
                    size={"small"}
                    fullWidth
                    placeholder="쿠폰명을 입력해 주세요."
                    value={messageButton.name}
                    onChange={(e) =>
                      changeMessageButton(index, "name", e.target.value)
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      mt: 1,
                    }}
                  >
                    <TextField
                      sx={{ flex: 1 }}
                      size={"small"}
                      placeholder="연결할 쿠폰을 선택해주세요"
                      value={messageButton.coupon?.name}
                      disabled={true}
                      readOnly
                    />
                    <NormalButton
                      variant={"outlined"}
                      onClick={() => setOpenConnectionPopup(index)}
                    >
                      쿠폰 연결하기
                    </NormalButton>
                  </Box>
                </Box>
              )}
            </Box>
            {index + 1 < messageButtons.length && (
              <Divider sx={{ mt: 2, mb: 4 }} />
            )}
          </Box>
        );
      })}
      {isOpenConnectionPopup != null && (
        <SuspensePopup
          isEditPopup={true}
          title={"쿠폰 연결하기"}
          size={"w-[1200px]"}
          onClose={() => setOpenConnectionPopup(null)}
        >
          <CouponConnectionForm
            onClick={(coupon) =>
              handleMappingCouponUpdate(isOpenConnectionPopup, coupon)
            }
            used={true}
          />
        </SuspensePopup>
      )}
    </>
  );
};

export default WideMessageSetting;
