import {RadioGroup, Table, TextInput, Toggle} from "omoplay";
import {useEffect, useState} from "react";
import CircleBadge from "./CircleBadge";

const BenefitQuantityDetailSettings = ({benefitQuantitySetting, index, isAllLimit, choiceDefaultBenefits,
                                           onBenefitQuantity, onEntryPointQuantity, isError}) => {
    const events = benefitQuantitySetting.events
    const entryPointDetailQuantities = events.flatMap((event) => {
        return event.entryPointQuantities.map(entryPoint => {
            return (
                {
                    prevAllocatedQuantity: entryPoint.allocatedQuantity,
                    checkbox: entryPoint.allocatedQuantity !== null
                }
            )
        })
    })
    const [tableRenderData, setTableRenderData] = useState([])
    const [infinity, setInfinity] = useState(false)
    const [entryPointQuantities, setEntryPointQuantities] = useState(entryPointDetailQuantities)
    const [limitQuantity, setLimitQuantity] = useState(benefitQuantitySetting.quantityLimit)
    const [errorMessage, setErrorMessage] = useState("")
    const [unAllocatedQuantity, setUnAllocatedQuantity] = useState(0)
    const [disabledLimit, setDisabledLimit] = useState(false)

    useEffect(() => {
        const isDisabled = benefitQuantitySetting.id === null || choiceDefaultBenefits.some(benefit => benefit.benefitId === benefitQuantitySetting.id)
        setDisabledLimit(isDisabled)
        setInfinity(isDisabled || !benefitQuantitySetting.quantityLimit)
    }, [benefitQuantitySetting, choiceDefaultBenefits])

    const headers = [
        {name: "이벤트"}, {name: "진입점"}, {name: "발급 수량", width: "140px"},
        {name: "재고량 (남은 수량)", width: "180px"}, {name: "배분 수량", width: "180px"},
        {name: (<div></div>), width: "120px"}
    ]

    let rIndex = 0

    const handleChange = (changeEvent, entryPoint, quantityIndex) => {
        const remainValue = Number(changeEvent.currentTarget.value)
        const allocatedValue = remainValue - entryPoint.remainQuantity
        const newEntryPointQuantity = [...entryPointQuantities]
        newEntryPointQuantity[quantityIndex] = {
            ...newEntryPointQuantity[quantityIndex],
            remainQuantity: remainValue,
            allocatedQuantity: allocatedValue
        }
        setEntryPointQuantities(newEntryPointQuantity)
        onEntryPointQuantity(entryPoint.id, entryPoint.allocatedQuantity + allocatedValue)
    }

    useEffect(() => {
        let totalIssuedQuantity = 0
        let totalRemainQuantity = 0
        let totalAllocatedQuantity = 0

        const render =
            events.flatMap((event) => {
                return event.entryPointQuantities.map((entryPoint, index) => {
                    const quantityIndex = rIndex++
                    let allocatedQuantity = entryPointQuantities[quantityIndex].allocatedQuantity ?? 0

                    totalIssuedQuantity += entryPoint.issuedQuantity
                    totalRemainQuantity += entryPointQuantities[quantityIndex].remainQuantity ?? entryPoint.remainQuantity
                    totalAllocatedQuantity += entryPointQuantities[quantityIndex].checkbox ? (entryPoint.allocatedQuantity ?? entryPoint.issuedQuantity) + allocatedQuantity : entryPoint.issuedQuantity ?? 0

                    return [
                        <div>{index === 0 && event.name}</div>,
                        <div>{entryPoint.name}</div>,
                        <div>{entryPoint.issuedQuantity}</div>,
                        <div>
                            {
                                ((isAllLimit || !infinity) && entryPointQuantities[quantityIndex].checkbox) ? (
                                    <TextInput type="numeric"
                                               style={{height: "11px"}}
                                               key={`${entryPointQuantities[quantityIndex].checkbox}`}
                                               defaultValue={entryPoint.remainQuantity}
                                               value={entryPointQuantities[quantityIndex].remainQuantity}
                                               onChange={(e) => handleChange(e, entryPoint, quantityIndex)}
                                    />
                                ) : (<div>-</div>)
                            }
                        </div>,
                        <div className="flex gap-2">
                            {
                                entryPointQuantities[quantityIndex].checkbox ? (
                                    <>
                                        <div>{(entryPoint.allocatedQuantity ?? entryPoint.issuedQuantity) + allocatedQuantity}</div>
                                        <div className="font-bold">
                                            {
                                                allocatedQuantity ? (
                                                    allocatedQuantity > 0 ? (
                                                        <div className="text-mainblue-0">(+{allocatedQuantity})</div>
                                                    ) : (<div className="text-red-0">({allocatedQuantity})</div>)
                                                ) : <></>
                                            }
                                        </div>
                                    </>
                                ) : (<div className="text-grey-4">배분 가용 수량 내 무제한</div>)
                            }
                        </div>,
                        <div>
                            {
                                (isAllLimit || !infinity) && (
                                    <Toggle
                                        value={entryPointQuantities[quantityIndex].checkbox}
                                        onToggle={(flag) => {
                                            const newEntryPointQuantities = [...entryPointQuantities]
                                            newEntryPointQuantities[quantityIndex] = {
                                                ...newEntryPointQuantities[quantityIndex],
                                                checkbox: flag
                                            }
                                            if (!flag) {
                                                newEntryPointQuantities[quantityIndex].remainQuantity = undefined
                                            }
                                            setEntryPointQuantities(newEntryPointQuantities)
                                            let allocatedQuantitySum = 0
                                            newEntryPointQuantities.forEach((value) =>
                                                allocatedQuantitySum += value.checkbox ? ((value.prevAllocatedQuantity ?? 0) + (value.allocatedQuantity ?? 0)) : (entryPoint.issuedQuantity ?? 0)
                                            )
                                            setUnAllocatedQuantity(limitQuantity - allocatedQuantitySum)
                                            onEntryPointQuantity(entryPoint.id, newEntryPointQuantities[quantityIndex].allocatedQuantity)
                                        }}
                                    />
                                )
                            }
                        </div>
                    ]
                })
            })

        render.push([
            <div>합계</div>,
            <div></div>,
            <div>{totalIssuedQuantity}</div>,
            <div>{totalRemainQuantity}</div>,
            <div>{totalAllocatedQuantity}</div>,
            <div></div>,
        ])
        setTableRenderData(render)
        const limit = limitQuantity - totalAllocatedQuantity
        setUnAllocatedQuantity(limit)
        console.log(limitQuantity, totalAllocatedQuantity, totalIssuedQuantity)
        if (limit < 0) {
            setErrorMessage("* 미 배분 수량은 0보다 작을 수 없습니다. 총 배분 수량 제한을 늘려주세요.")
            isError(true)
        } else {
            setErrorMessage("")
            isError(false)
        }
    }, [entryPointQuantities, infinity, isAllLimit, limitQuantity]);


    return (
        <div className="flex-col gap-8">
            <div>
                <div className="flex items-center gap-2">
                    <CircleBadge label={index + 1} />
                    <div>{benefitQuantitySetting.name}</div>
                </div>
                <div className="flex border rounded-b gap-4" style={{height: "56px"}}>
                    <div className="flex items-center" style={{backgroundColor: "var(--grey-1)", width: "200px", paddingLeft: "24px"}}>
                        <div>전체 수량</div>
                    </div>
                    <div className="flex items-center">
                        {
                            isAllLimit ? (<div>총 배분 수량 제한</div>) : (
                                <RadioGroup
                                    key={`${infinity}`}
                                    items={[
                                        {
                                            label: "무제한",
                                            value: "infinity"
                                        },
                                        {
                                            label: "총 배분 수량 제한",
                                            value: "limit",
                                            disabled: disabledLimit
                                        }
                                    ]}
                                    initChecked={infinity ? "infinity" : "limit"}
                                    onSelected={(value) => {
                                        const isInfinity = value === "infinity"
                                        setInfinity(isInfinity)
                                        setErrorMessage("")
                                        setLimitQuantity(isInfinity ? undefined : 0)
                                        onBenefitQuantity(isInfinity ? undefined : 0)
                                    }}
                                />
                            )
                        }
                    </div>
                    <div className="flex items-center">
                        {
                            (isAllLimit || !infinity) && (
                                <TextInput type="numeric"
                                           value={limitQuantity}
                                           onChange={(event) => {
                                               const value = Number(event.currentTarget.value)
                                               setLimitQuantity(value)
                                               onBenefitQuantity(value)
                                           }} />
                            )
                        }
                    </div>
                    {
                        (isAllLimit || !infinity) && (
                            <div className="flex items-center gap-2">
                                <div>미 배분 수량: <span className="text-mainblue-0 font-bold">{unAllocatedQuantity}</span></div>
                                <div className="text-red-500">{errorMessage}</div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div>
                <div className="text-grey-7 font-bold">진입점별 수량 제한</div>
                <div>
                    <Table headers={headers} data={tableRenderData} isLastRowTotal={true} />
                </div>
            </div>
        </div>
    )
}

export default BenefitQuantityDetailSettings