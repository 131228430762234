import {
  Box,
  Typography,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import { useRef, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CarouselTypeContent = ({ data }) => {
  const scrollRef = useRef(null);
  const [isDrag, setIsDrag] = useState(false);
  const [startX, setStartX] = useState();

  const onDragStart = (e) => {
    e.preventDefault();
    setIsDrag(true);
    setStartX(e.pageX + scrollRef.current.scrollLeft);
  };

  const onDragEnd = () => {
    setIsDrag(false);
  };

  const onDragMove = (e) => {
    if (isDrag) {
      scrollRef.current.scrollLeft = startX - e.pageX;
    }
  };

  const isMoreButton =
    data.messageButtons &&
    data.messageButtons.filter((button) => button.link).length > 0;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          overflowX: "auto",
          width: "312px",
          gap: 1,
          transition: ".2s",
          marginLeft: "-40px",
        }}
        className="hide-scrollbar"
        onMouseDown={onDragStart}
        onMouseMove={onDragMove}
        onMouseUp={onDragEnd}
        onMouseLeave={onDragEnd}
        ref={scrollRef}
      >
        <Box sx={{ width: "32px", flex: "0 0 auto" }}></Box>
        {data.listItems &&
          data.listItems
            .filter((item) => item.image != null)
            .map((item, index) => {
              return (
                <Box
                  sx={{
                    width: "220px",
                    flex: "0 0 auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  className="message-content"
                  key={"preview-carousel-item" + index}
                >
                  {item.image && (
                    <Box
                      className="preview-image-wrap"
                      sx={{
                        width: "100%",
                        height: "165px",
                        backgroundImage: `url(${item.image})`,
                      }}
                    ></Box>
                  )}
                  <Box className="default-message-content">
                    <Typography fontSize={14}>{item.title}</Typography>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                    <Typography
                      sx={{ whiteSpace: "break-spaces" }}
                      fontSize={12}
                    >
                      {item.content}
                    </Typography>
                  </Box>
                    <Box className="flex" style={{padding: "8px", gap: "5px"}}>
                        {item.messageButtons &&
                            item.messageButtons
                                .filter((button) => button.isLink === "link" || button.isLink === "coupon")
                                .map((button, index) => {
                                    return (
                                        <Button
                                            className="preview-button"
                                            variant="outlined"
                                            color={"grey7"}
                                            onClick={() => window.open(button.link)}
                                            sx={{ flex: 1, mt: 2, padding: "0 4px 0 0" }}
                                        >
                                            {button.name || "메시지 버튼"}
                                        </Button>
                                    );
                                })}
                    </Box>
                </Box>
              );
            })}
        <Box
          sx={{
            width: isMoreButton ? "47px" : "8px",
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isMoreButton &&
            data.messageButtons
              .map((button) => {
                return (
                  <Box sx={{ textAlign: "center" }}>
                    <IconButton
                      onClick={() => window.open(button.link)}
                      sx={{ backgroundColor: "#FFF" }}
                    >
                      <ArrowForwardIcon />
                    </IconButton>
                    <Typography fontSize={9} color={"grey7"} sx={{ mt: 0.5 }}>
                      더보기
                    </Typography>
                  </Box>
                );
              })}
        </Box>
      </Box>
      <Box sx={{ textAlign: "right" }}>
        <Typography fontSize={9} color={"grey7"} sx={{ mt: 1, mr: 2 }}>
          수신거부 | 홈 > 채널차단
        </Typography>
      </Box>
    </Box>
  );
};

export default CarouselTypeContent;
