import MobilePreview from "../../MobilePreview";

const EventLoginPreview = ({loginBackgroundImage, loginButtonName, loginPrecautionName, loginPrecautionContent}) => {
    const API_HOST = process.env.REACT_APP_API_HOST
    return (
        <MobilePreview>
            <iframe
                className="relative h-full"
                src={`${API_HOST}/view/login?temp=true` +
                `&loginBackgroundImage=${loginBackgroundImage}&loginButtonName=${loginButtonName}` +
                `&introduceButton=${loginPrecautionName}` +
                `&introduceTitle=${loginPrecautionContent}`
            }
                title="OMOPLAY TEMP"
            />
        </MobilePreview>
    )
}

export default EventLoginPreview