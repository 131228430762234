import { API_URL } from "../../constants/axios";
import { fetchApi } from "../fetch";

export const getCoupons = async (
  searchType = "",
  searchValue = "",
  page = 0,
  size = 9999,
  sort = "status,desc",
  used = ""
) => {
  const searchParam = new URLSearchParams({
    searchType,
    searchValue,
    sort,
    page,
    size,
    used
  });
  const res = await fetchApi.get(
    API_URL.GET_COUPONS + "?" + searchParam.toString()
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

export const getCoupon = async (id) => {
  const res = await fetchApi.get(API_URL.GET_COUPON.replace("{id}", id));
  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const refreshCoupons = async () => {
  const res = await fetchApi.post(API_URL.REFRESH_COUPONS);
  if (res.status === 200) {
    return res.data;
  }
  return false;
};

export const createCoupon = async (data) => {
  const res = await fetchApi.post(API_URL.CREATE_COUPON, data);
  if (res.status === 200) {
    return res.data;
  }
  return null;
};

export const updateCoupon = async (couponId, data) => {
  const res = await fetchApi.put(
    API_URL.UPDATE_COUPON.replace("{id}", couponId),
    {
      notificationImage: data.notificationImage,
      notificationMessage: data.notificationMessage,
      couponImage: data.couponImage,
      additionalTitle: data.additionalTitle,
      additionalDescription: data.additionalDescription,
    }
  );
  if (res.status === 200) {
    return res.data;
  }
  return null;
};

export const deleteCoupons = async (couponIds) => {
  const res = await fetchApi.post(API_URL.DELETE_COUPON, {
    ids: couponIds,
  });
  if (res.status === 200) {
    return res.data;
  }
  return null;
};

export const getCouponStatistics = async (spotId) => {
  const res = await fetchApi.get(
    API_URL.GET_COUPONS_STATISTICS.replace("{spotId}", spotId)
  );
  if (res.status === 200) {
    return res.data;
  }
  return {};
};
