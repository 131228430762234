export const couponSearchTypes = [
  {
    label: "쿠폰 업체",
    value: "company",
    items: [
      { label: "쿠프마케팅", value: "쿠프마케팅" },
      { label: "페이즈", value: "페이즈" },
      { label: "헤드리스", value: "헤드리스" },
    ],
  },
  { label: "쿠폰명", value: "name" },
  { label: "유효기간", value: "available_date" },
  { label: "쿠폰 금액", value: "price" },
  { label: "쿠폰 형식", value: "type" },
];
