import { useEffect, useState } from "react";
import MessageContent from "../volatility-message/content/MessageContent";
import MessageDefaultInfo from "../volatility-message/MessageDefaultInfo";
import AutomateMessageCondition from "../form/automatemessage/AutomateMessageCondition";
import AutomateMessageConfirm from "../form/automatemessage/AutomateMessageConfirm";

const AutomateMessageSettings = ({
  activeTab,
  onChangeTab,
  automateMessageData,
  setAutomateMessageData
}) => {
  const [messageType, setMessageType] = useState(automateMessageData?.message?.messageType ?? "default");

  const [data, setData] = useState({
    isAdd: true,
    messageButtons: [{ isLink: 'none' }, { isLink: 'none' }],
  });

  const handleDefaultInfoChange = (data) => {
    if (messageType !== data.messageType) {
      if (window.confirm("현재 작성된 데이터가 초기화 됩니다. 진행하시겠습니까?")) {
        setMessageType(data.messageType)
        let updateData = {
          ...data,
          selectedMessageType: data,
          isAdd: true,
          messageButtons:
              data.messageType === "carousel"
                  ? [{ isLink: 'none' }]
                  : [{ isLink: 'none' }, { isLink: 'none' }],
        };
        setData(updateData);
      }
    }
  };

  const handleChangeContent = (data) => {
    setData(data);
    automateMessageData.previewMessage = data
  };

  useEffect(() => {
    if (automateMessageData) {
      automateMessageData.messageType = messageType
      if (automateMessageData.previewMessage) {
        setData(automateMessageData.previewMessage)
      }
    }
  }, [automateMessageData])


  if (automateMessageData && data) {
    const renderWrap = (tab) => {
      if (tab === "default") {
        return (
            <MessageDefaultInfo
                campaignName={automateMessageData.name}
                messageType={messageType}
                onChange={handleDefaultInfoChange}
            />
        );
      } else if (tab === "content") {
        return (
            <MessageContent
                messageType={messageType}
                data={data}
                onChange={handleChangeContent}
            />
        );
      } else if (tab === "condition") {
        return (
            <AutomateMessageCondition
                automateMessageData={automateMessageData}
                setAutomateMessageData={setAutomateMessageData}
            />
        );
      } else if (tab === "confirm") {
        return (
            <AutomateMessageConfirm
                campaignName={automateMessageData.name}
                messageType={messageType}
                onClick={onChangeTab}
                data={data}
            />
        );
      }
    }
    return renderWrap(activeTab);
  }
};

export default AutomateMessageSettings;
