import CarouselMessageSetting from "./CarouselMessageSetting.js";
import DefaultMessageSetting from "./DefaultMessageSetting";
import WideListMessageSetting from "./WideListMessageSetting";
import WideMessageSetting from "./WideMessageSetting";
import { Box } from "@mui/material";

const MessageSetting = ({ data, type, onChange }) => {
  const renderSettingOfType = (type) => {
    if (type === "default") {
      return (
        <DefaultMessageSetting type={type} data={data} onChange={onChange} />
      );
    } else if (type === "wide") {
      return <WideMessageSetting type={type} data={data} onChange={onChange} />;
    } else if (type === "wide_list") {
      return (
        <WideListMessageSetting type={type} data={data} onChange={onChange} />
      );
    } else if (type === "carousel") {
      return (
        <CarouselMessageSetting type={type} data={data} onChange={onChange} />
      );
    }
  };
  return (
    <Box className="card" sx={{ width: "60%", marginBottom: "80px" }}>
      <Box sx={{ p: 4 }}>{renderSettingOfType(type)}</Box>
    </Box>
  );
};

export default MessageSetting;
