import Customers from "../statistics/Customers";
import CalendarSearchBar from "../CalendarSearchBar";
import {useEffect, useState} from "react";
import {getDefaultDateRange} from "../../libs/helper";
import {getCustomersStatistics} from "../../api/statistics/statistics.api";
import SpecificCustomerStatistics from "../page/SpecificCustomerStatistics";


const GraphStatistics = ({periodStartDate, projectId}) => {
    const [dateRange, setDateRange] = useState(getDefaultDateRange())
    const [customers, setCustomers] = useState([])

    useEffect(() => {
        getCustomersStatistics(dateRange.startDate, dateRange.endDate, projectId).then(({data}) => {
            setCustomers(data)
        })
    }, [dateRange, projectId]);

    return (
        <div className="flex-col gap-4">
            <div className="flex-col gap-2">
                <div className="font-bold font-20px">참여 고객 추이</div>
                <div className="font-medium font-16px">기간별 참여 고객 추이를 확인하실 수 있습니다</div>
                <div>
                    <CalendarSearchBar
                        periodStartDate={periodStartDate}
                        onConfirm={(dateProps) => {
                            setDateRange(dateProps)
                        }} />
                </div>
            </div>
            <Customers customers={customers} />
            <SpecificCustomerStatistics customers={customers} />
        </div>
    )
}

export default GraphStatistics