import { useCallback, useEffect, useState } from "react";
import { duplicateCheck } from "../../api/spot/spot.api";
import ActiveButton from "../ActiveButton";

const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");
const { default: ContentMask } = require("../ContentMask");
const { faXmark } = require("@fortawesome/free-solid-svg-icons");

const QREditForm = ({
  data,
  onClose,
  benefit,
  onConfirm,
}) => {
  const [name, setName] = useState(data?.name);
  const [isDuplicate, setDuplicate] = useState(false);
  const [benefitId, setBenefitId] = useState(data?.benefit?.id)
  const [isRequest, setRequest] = useState(false)

  const isEdit = data?.spotId != null;

  const checkName = useCallback(async () => {
    const res = await duplicateCheck(name);
    setDuplicate(data?.name !== name && !res.data);
  }, [data, name]);

  useEffect(() => {
    if (name) {
      checkName();
    }
  }, [checkName, name]);

  useEffect(() => {
    if (benefit) {
      setBenefitId(benefit.id);
    }
  }, [benefit]);

  const handleConfirm = useCallback(async () => {
    if (!isDuplicate) {
      setRequest(true)
      onConfirm(name, benefitId);
    }
  }, [benefitId, isDuplicate, name, onConfirm]);

  return (
    <ContentMask>
      <div key={data} className="relative w-full max-w-xl max-h-full">
        <div className="relative bg-white rounded-lg shadow py-4 px-8">
          <div className="w-100 border-b border-b-gray-300 flex justify-between pt-2 pb-4 items-center">
            <h4 className="text-xl font-bold">
              진입점 {isEdit ? " 수정" : "추가"}
            </h4>
            <button onClick={onClose}>
              <FontAwesomeIcon icon={faXmark} size={"lg"} color={"#999"} />
            </button>
          </div>
          <div className="description-bar">
            <div>
              <div>프로젝트(이벤트)와 연결하여 사용하실 수 있으며, 진입점에 연결된 프로젝트가 없을 때는 고객들에게 ‘종료된 이벤트입니다' 페이지로 안내됩니다.</div>
            </div>
          </div>
          <div className="py-4 flex-col gap-8">
            <div>
              <div className="flex items-center">
                <label className="text-md font-medium text-gray-900  relative">
                  진입점명
                  <span className="absolute text-red-500 -end-2 -top-1">*</span>
                </label>
                <label
                  className={
                    "text-red-500 ml-3 text-sm transition-all " +
                    (isDuplicate && data?.name !== name
                      ? "opacity-100"
                      : "opacity-0")
                  }
                >
                  중복된 이름 입니다.
                </label>
              </div>
              <p className="text-gray-500 text-sm mb-2">
                진입점명을 설정해 주세요.
              </p>
              <div className="relative">
                <input
                  type="text"
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2"
                  placeholder="ex) 광화문점"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={20}
                  required
                />
                <span className="absolute end-3 bottom-3 text-xs text-gray-400">
                  {name?.length || 0}/20자
                </span>
              </div>
            </div>

            <div className={`w-100 mx-auto mt-8 flex gap-4 justify-center`}>
              <ActiveButton
                className="px-20 py-2"
                disabled={!name || isDuplicate || isRequest}
                onClick={() => handleConfirm()}
              >
                진입점 {isEdit ? "수정" : "추가"}
              </ActiveButton>
            </div>
          </div>
        </div>
      </div>
    </ContentMask>
  );
};

export default QREditForm;
