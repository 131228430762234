import { DefaultValue, selector, selectorFamily } from "recoil";
import { couponState } from "../state/coupon";
import { getCoupons } from "../../api/event/coupon.api";

export const couponSelector = selector({
  key: "couponSelector",
  get: async ({ get }) => {
    const res = await getCoupons();
    if (res.data) {
      return res.data.content;
    }
    return get(couponState);
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(couponState);
    } else if (typeof newValue === "number") {
      set(couponState, newValue);
    }
  },
});

export const couponFamilySelector = selectorFamily({
  key: "couponFamilySelector",
  get:
    (param) =>
    async ({ get }) => {
      const [type, value, page, size, sort, used] = param.split("|");
      const res = await getCoupons(type.toUpperCase(), value, page, size, sort, used);
      if (res.data) {
        return res.data;
      }
      return get(couponState);
    },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(couponState);
    } else if (typeof newValue === "number") {
      set(couponState, newValue);
    }
  },
});
