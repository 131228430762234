
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUsers } from "../../api/user/user.api";
import { getAllSpots } from "../../api/spot/spot.api";
import SearchInput from "../SearchInput";
import {CalendarBar, Dropdown, Pagination, Table} from "omoplay";
import {getAllDateRange} from "../../libs/helper";
const moment = require("moment");

const UserListPage = () => {
  const [users, setUsers] = useState({ content: [] });
  const [search, setSearch] = useState("");
  const [spotId, setSpotId] = useState("");
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState(getAllDateRange());
  const [spots, setSpots] = useState([]);

  const loadSpots = async () => {
    const res = await getAllSpots();
    if (res.code === 2000) {
      setSpots(res.data);
    }
  };

  useEffect(() => {
    loadSpots().then(() => {
      const { startDate, endDate } = dateRange;
      getUsers(spotId, search, startDate, endDate, page - 1, 10, "createdAt,desc").then((res) => {
        if (res.code === 2000) {
          setUsers(res.data);
        } else {
          alert(res.message);
        }
      })
    })
  }, [spotId, search, dateRange, page]);

  const handleSpotChange = (spotId) => {
    setPage(1)
    setSpotId(spotId);
  };
  const handleSearchChange = (search) => {
    setPage(1)
    setSearch(search);
  };

  const getSpotName = (spotId) => {
    if (spotId != null) {
      const findIndex = spots.findIndex((spot) => spot.spotId === spotId);
      if (findIndex > -1) {
        return spots[findIndex].isDeleted ? (
          spots[findIndex].name
        ) : (
          <Link
            to={`/spot?spotId=${spotId}`}
            className="text-indigo-500 hover:underline"
          >
            {spots[findIndex].name}
          </Link>
        );
      }
    }
    return "";
  };

  const onDateInput = (dateRange) => {
    setPage(1)
    setDateRange({
      startDate: moment(dateRange.startDate).format("yyyy-MM-DD"),
      endDate: moment(dateRange.endDate).format("yyyy-MM-DD")
    });
  };

  const displayGenderText = (gender) => {
    if (gender == "male") {
      return "남성";
    } else if (gender == "female") {
      return "여성";
    } else {
      return gender;
    }
  };

  const headers = [
    { name: "이름", align: "center", width: "100px" },
    { name: "이메일(카카오계정)", width: "180px" },
    { name: "성별", align: "center", width: "100px" },
    { name: "연령", align: "center", width: "120px" },
    { name: "전화번호", width: "180px" },
    { name: "배송지 주소" },
    { name: "회원가입", width: "200px"  },
    { name: "마케팅 동의", width: "120px" }
  ];

  const tableData = users.content.map(user => {
    return [
        <div className="text-center">{user.name}</div>,
      user.email,
        <div className="text-center">{displayGenderText(user.gender)}</div>,
        <div className="text-center">
          <div>{user.ageRange}</div>
          <div>({`${user.birthyear ? `${user.birthyear}.` : ''}`}{user.birthday
              ? user.birthday.slice(0, 2) +
              "." +
              user.birthday.slice(2, 4)
              : ""})
          </div>
        </div>,
      user.phoneNumber,
        <>
          <span
              className="float-left"
              style={{
                maxWidth: "190px",
                overflowX: "hidden",
                whiteSpace: "nowrap"
              }}
          >
            {user.baseAddress}
          </span>
          <span>&nbsp;&nbsp;</span>
          <span>********</span>
        </>,
        <>
          <div
              className="flex"
              style={{
                maxWidth: "180px",
                textOverflow: "ellipsis",
                overflowX: "clip",
                whiteSpace: "nowrap"
              }}
          >
          {getSpotName(user.spotId)}
          </div>
          <div>
            {moment(user.createdAt).format("YYYY.MM.DD HH:mm")}
          </div>
        </>,
        <div className="text-center">
          {user.marketingAgree ? '수신' : '미수신'}
        </div>
    ]
  })

  return (
    <>
      <div className="flex-col rounded w-full">
        <div className="page-title">고객 리스트</div>
        <div className="flex items-end" style={{marginTop: "35px", gap: "24px"}}>
          <div style={{maxWidth: "405px", width: "405px"}}>
            <div style={{marginBottom: "9px"}}>
              <label className="search-label">검색어</label>
            </div>
            <SearchInput onSubmit={(text) => handleSearchChange(text)} />
          </div>
          <div>
            <div style={{marginBottom: "9px"}}>
              <label className="search-label">진입점</label>
            </div>
            <Dropdown variants="default"
                      items={[{label: "전체", value: ""}, ...spots.map(spot => ({label: spot.name, value: spot.spotId}))]}
                      onChanged={(items) => handleSpotChange(items[0].value)}
                      titleStyle={{backgroundColor: "white"}}
            />
          </div>
          <div>
            <div style={{marginBottom: "9px"}}>
              <label className="search-label">회원 가입일</label>
            </div>
            <CalendarBar initDate={{startDate: moment(dateRange.startDate).toDate(), endDate: moment(dateRange.endDate).toDate()}}
                         onConfirm={(props) => onDateInput(props)} />
          </div>
        </div>
        <div style={{marginTop: "30px", marginLeft: "14px"}}>
          <span className="font-16px">
            총 <span className="mainblue-0">{users.totalElements?.toLocaleString()}</span>명
          </span>
        </div>
        <div className="flex-col py-4">
          <Table headers={headers} data={tableData} />
          <div className="flex justify-center" style={{marginTop: "24px"}}>
            <Pagination rowSize={10} totalSize={users.totalElements} page={page} onPaging={(page) => setPage(page)} />
          </div>
        </div>
      </div>
    </>
  );
};
export default UserListPage;
