import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBullseye, faC,
  faComputerMouse,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import {useSearchParams} from "react-router-dom";
import {getDetailProject, postNameProject} from "../../../api/project/project.api";
import {Button, TabBar, TextInput} from "omoplay";
import {useEffect, useState} from "react";
import ProjectDetailSummary from "./ProjectDetailSummary";
import GraphStatistics from "../GraphStatistics";
import EntryPointStatistics from "../EventStatistics";
import BenefitStatistics from "../BenefitStatistics";
import QuantitySettings from "./QuantitySettings";
import Badge from "../badge/Badge";
import EditPenIcon from "../../../assets/images/edit_pen_icon.svg"

export const ProjectDetail = () => {
  const [query] = useSearchParams();
  const [project, setProject] = useState({})
  const [tab, setTab] = useState("summary")
  const [isEditName, setIsEditName] = useState(false)
  const [editProjectName, setEditProjectName] = useState("")
  const id = query.get("id")
  const tabItems = [
    { label: "개요", value: "summary", selected: true},
    { label: "수량 설정", value: "quantity"},
    { label: "혜택 성과", value: "benefitStatistics"},
    { label: "고객 분석", value: "userStatistics"},
    { label: "진입점 분석", value: "eventStatistics"}
  ]

  useEffect(() => {
    getDetailProject(id).then(({data}) => {
      setProject(data)
      setEditProjectName(data.name)
    })
  }, [id])

  const tabRender = () => {
    if (tab === "summary") {
      return <ProjectDetailSummary projectId={id} project={project}
                                   benefitPassword={project.benefitPassword} />
    } else if (tab === "quantity") {
      return <QuantitySettings projectId={id} />
    } else if (tab === "benefitStatistics") {
      return <BenefitStatistics projectId={id} />
    } else if (tab === "userStatistics") {
      return <GraphStatistics periodStartDate={project.startDate} projectId={id} />
    } else if (tab === "eventStatistics") {
      return <EntryPointStatistics periodStartDate={project.startDate} projectId={id} />
    }
  }

  return (
      <div className="flex-col gap-4" style={{ width: "1248px" }}>
        <div>
          <div className="flex gap-4 items-center mb-2">
            <div className="bg-white" style={{borderRadius: "6px"}}>
              <Button variants="default"
                      style={{width: "40px"}}
                      onClick={() => window.location.href = ("/project")}
                      value={<FontAwesomeIcon icon={faArrowLeft} />}
              />
            </div>
            {
              isEditName ? (
                  <div>
                    <TextInput value={editProjectName}
                               onChange={(e) => setEditProjectName(e.target.value)}
                               onKeyUp={(e) => {
                                 if (e.key === "Enter") {
                                   postNameProject(project.id, editProjectName).then(() => {
                                     project.name = editProjectName
                                     setIsEditName(false)
                                   })
                                 } else if (e.key === "Escape") {
                                   setIsEditName(false)
                                 }
                               }}
                    />
                  </div>
              ) : (<h4 className="text-3xl font-medium">{project.name}</h4>)
            }
            <div className="pointer" onClick={() => {
              if (isEditName) {
                postNameProject(project.id, editProjectName).then(() => {
                  project.name = editProjectName
                  setIsEditName(false)
                })
              } else {
                setIsEditName(true)
              }
            }}>
              <img src={EditPenIcon} />
            </div>
            <div><Badge name={project.statusKor} color={project.badgeColor} /></div>
          </div>
          <div className="flex-col gap-20 mt-8">
            <div className="flex-col gap-2">
              <div className="flex gap-2 white border border-gray-300 rounded p-4 bg-white">
                <div className="font-semibold">프로젝트 기간:</div>
                <div className="text-grey-7">{project.period}</div>
              </div>
              <div className="white border border-gray-300 rounded bg-white divide-x h-[135px] flex">
                <div className="flex-col justify-between w-1/3 p-4 h-full">
                  <div className="flex gap-2 items-center">
                    <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                      <FontAwesomeIcon icon={faBullseye} size="sm" />
                    </div>
                    <p className="font-semibold text-sm">
                      프로젝트
                    </p>
                  </div>
                  <div className="flex gap-2 justify-end">
                    {
                      project.statusKor === "종료됨" ? (
                          <div className="font-bold" style={{fontSize: "24px"}}>
                            종료됨
                          </div>
                      ) : (
                          <>
                            <div className="font-bold" style={{fontSize: "24px"}}>
                              {project.days >= 0 ? `${project.days + 1}일차` : `D-${Math.abs(project.days)}일`}
                            </div>
                            {
                              (project.remainDays !== null && project.remainDays >= 0) ? (
                                    <div style={{fontSize: "24px"}}>
                                      ({project.remainDays}일 남음)
                                    </div>
                                ) : <></>
                            }
                          </>
                      )
                    }
                  </div>
                </div>
                <div className="flex-col justify-between w-1/3 p-4 h-full">
                  <div className="flex gap-2 items-center">
                    <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                      <FontAwesomeIcon icon={faDollarSign} size="sm" />
                    </div>
                    <p className="font-semibold text-sm">참여 고객</p>
                  </div>
                  <div className="flex gap-2 justify-end">
                    <div className="font-bold" style={{fontSize: "24px"}}>
                      {project.joinUserNum?.toLocaleString()}명
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-between w-1/3 p-4 h-full">
                  <div className="flex gap-2 items-center">
                    <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                      <FontAwesomeIcon icon={faComputerMouse} size="sm" />
                    </div>
                    <p className="font-semibold text-sm">
                      노출 수
                      <div className="inline-block align-text-top ml-2">
                      </div>
                    </p>
                  </div>
                  <div className="flex gap-2 justify-end">
                    <div className="font-bold" style={{fontSize: "24px"}}>
                      {project.totalViews?.toLocaleString()}번
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-between w-1/3 p-4 h-full">
                  <div className="flex gap-2 items-center">
                    <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                      <FontAwesomeIcon icon={faC} size="sm" />
                    </div>
                    <p className="font-semibold text-sm">마케팅 비용</p>
                  </div>
                  <div className="flex gap-2 justify-end">
                    <div className="font-bold" style={{fontSize: "24px"}}>
                      {project.totalMarketingPrice?.toLocaleString()}원
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-col gap-8">
          <div style={{width: "100%", borderBottom: "1px solid var(--grey-2)"}}>
            <TabBar variants="underline"
                    items={tabItems}
                    onChanged={(item) => {
                      setTab(item.value)
                    }}
                    style={{width: "fit-content"}}
            />
          </div>
          <div>
            {
              tabRender()
            }
          </div>
          <div style={{height: "60px"}}></div>
        </div>
      </div>
  );
};
export default ProjectDetail;
