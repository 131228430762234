const OverlayBadge = ({ children, size = "md" }) => {
  const getSize = (size) => {
    if (size === "sm") {
      return "w-6 h-6 text-xs";
    } else {
      return "w-7 h-7 text-sm";
    }
  };
  return (
    <div
      className={
        "border-white border-2 rounded-full bg-black text-white font-semibold text-xl flex items-center justify-center " +
        getSize(size)
      }
    >
      {children}
    </div>
  );
};

export default OverlayBadge;
