const TextInput = ({
  type = "text",
  value,
  onChange,
  onBlur = () => {
    return;
  },
  onKeyDown = () => {
    return;
  },
  placeholder = "",
}) => {
  return (
    <input
      type={type}
      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
      placeholder={placeholder}
      value={value || ""}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
};

export default TextInput;
