import { useCallback, useEffect, useState } from "react";
import { MESSAGE_TYPES } from "../../constants/menus";
import MessageContent from "../volatility-message/content/MessageContent";
import {
  convertCodeToMessageType,
  convertResponseData,
  getExpectedCustomerCount,
  getVolatilityMessage,
} from "../../api/message/volatility-message.api";
import { useSearchParams } from "react-router-dom";
import MessageDefaultInfo from "../volatility-message/MessageDefaultInfo";
import MessageTargetSetting from "../volatility-message/MessageTargetSetting";
import moment from "moment";
import MessageConfirm from "../volatility-message/content/MessageConfirm";

const EditVolatilityMessage = ({
  activeTab,
  onChangeTab,
  onChange,
  convertFilter,
  isReserved,
  setReserved,
}) => {
  const [query] = useSearchParams();
  const [campaignName, setCampaignName] = useState();
  const [messageType, setMessageType] = useState(MESSAGE_TYPES[0].key);
  const [targetFilter, setTargetFilter] = useState([]);
  const [isCustomerLoading, setCustomerLoading] = useState(false);
  const [customerCount, setCustomerCount] = useState(0);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const [isFilter, setFilter] = useState(false);
  const [reserveDate, setReserveDate] = useState({
    date: null,
    hour: "00",
    min: "00",
  });

  const [data, setData] = useState({
    isAdd: true,
    messageButtons: [{ isLink: 'none' }, { isLink: 'none' }],
  });

  const getCustomerCount = useCallback(async () => {
    const requestData = convertFilter(targetFilter);
    const res = await getExpectedCustomerCount(requestData);

    setCustomerCount(res.data);
    setCustomerLoading(false);
    if (customerCount === 0 && totalCustomerCount === 0) {
      setTotalCustomerCount(res.data);
    }
  }, [targetFilter]);

  useEffect(() => {
    setCustomerLoading(true);
    getCustomerCount();
  }, [targetFilter, isFilter]);

  const handleDefaultInfoChange = (data) => {
    setCampaignName(data.campaignName);
    if (messageType !== data.messageType) {
      setMessageType(data.messageType);

      let updateData = {
        ...data,
        selectedMessageType: data,
        isAdd: true,
        messageButtons:
          data.messageType === "carousel"
            ? [{ isLink: 'none' }]
            : [{ isLink: 'none' }, { isLink: 'none' }],
      };
      setData(updateData);
    }
  };

  const handleChangeContent = (data) => {
    setData(data);
  };

  const handleChangeTarget = (data) => {
    const { targetFilter, reserveDate, isFilter, isReserved } = data;
    setTargetFilter([...targetFilter]);
    setReserveDate(reserveDate);
    setFilter(isFilter);
    setReserved(isReserved);
  };

  useEffect(() => {
    onChange({ campaignName, messageType, targetFilter, data, reserveDate });
  }, [campaignName, data, messageType, onChange, reserveDate, targetFilter]);

  const handleGetData = useCallback(async (id) => {
    const res = await getVolatilityMessage(id);
    if (res.data) {
      const data = res.data;
      const reserveDate = moment(data.issuedAt);
      setCampaignName(data.campaignName);
      setMessageType(convertCodeToMessageType(data.messageType));
      setTargetFilter(data.filters);
      if (data.filters && data.filters.length > 0) {
        setFilter(true);
      }
      setData(convertResponseData(data.messageData));
      if (reserveDate.isValid()) {
        setReserveDate({
          date: {
            startDate: reserveDate.format("yyyy-MM-DD"),
            endDate: reserveDate.format("yyyy-MM-DD"),
          },
          hour: Number(reserveDate.format("HH")),
          min: Number(reserveDate.format("mm")),
        });
        setReserved(true);
      }
    }
  }, []);

  useEffect(() => {
    const id = query.get("id");
    if (id) {
      handleGetData(id);
    }
  }, [handleGetData, query]);

  const renderWrap = (tab) => {
    if (tab === "default") {
      return (
        <MessageDefaultInfo
          campaignName={campaignName}
          messageType={messageType}
          onChange={handleDefaultInfoChange}
        />
      );
    } else if (tab === "content") {
      return (
        <MessageContent
          messageType={messageType}
          data={data}
          onChange={handleChangeContent}
        />
      );
    } else if (tab === "setting") {
      return (
        <MessageTargetSetting
          targetFilter={targetFilter}
          reserveDate={reserveDate}
          totalCustomerCount={totalCustomerCount}
          customerCount={customerCount}
          isCustomerLoading={isCustomerLoading}
          onChange={handleChangeTarget}
          isFilter={isFilter}
          isReserved={isReserved}
          messageType={messageType}
          data={data}
        />
      );
    } else if (tab === "confirm") {
      return (
        <MessageConfirm
          campaignName={campaignName}
          messageType={messageType}
          isReserved={isReserved}
          onClick={onChangeTab}
          targetFilter={targetFilter}
          customerCount={customerCount}
          reserveDate={reserveDate}
          data={data}
        />
      );
    }
  };
  return renderWrap(activeTab);
};

export default EditVolatilityMessage;
