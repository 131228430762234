import {
  API_URL,
  getMessageType,
  getMessageTypeName,
} from "../../constants/axios";
import { fetchApi, message_instance } from "../fetch";
import { cloneDeep } from "lodash";

export const saveScenario = (
  businessKey,
  mallId,
  profileKey,
  name,
  reservedAt,
  campaigns,
  successCallback = () => {},
  catchCallback = () => {}
) => {
  message_instance
    .post(API_URL.SCENARIO_SAVE, {
      businessKey,
      mallId,
      profileKey,
      name,
      reservedAt: reservedAt ? reservedAt.$d : "",
      campaigns: JSON.stringify(campaigns),
    })
    .then(successCallback)
    .catch((error) => {
      catchCallback(error);
    });
};

const convertImage = (imageUrl, imageLink) => {
  return {
    imgUrl: imageUrl,
    imgLink: imageLink,
  };
};
const convertButtons = (messageButtons) => {
  return messageButtons
    .filter((button) => button.isLink !== 'none')
    .map((button) => {
      const buttonData = {
        name: button.name,
        type: "WL",
      };
      if (button.isLink === "link") {
        buttonData.urlMobile = button.link
        buttonData.urlPc = button.pcLink
      } else if (button.isLink === "coupon" && button.coupon) {
        buttonData.couponId = button.coupon.id.toString();
      }
      return buttonData
    });
};
const convertListItems = (listItems) => {
  return listItems.map((item) => {
    return {
      title: item.content,
      imgUrl: item.image,
      urlMobile: item.link,
      urlPc: item.pcLink,
    };
  });
};
const convertCarouselItems = (listItems) => {
  return listItems
    .filter((item) => item.title && item.content)
    .map((item) => {
      return {
        header: item.title,
        message: item.content,
        attachment: {
          buttons: convertButtons(item.messageButtons),
          image: convertImage(item.image, item.link),
        },
      };
    });
};

const convertCarouselTail = (buttons) => {
  if (buttons.length > 0) {
    const button = buttons[0];
    if (button.link) {
      return {
        urlMobile: button.link,
        urlPc: button.pcLink,
      };
    } else {
      return null;
    }
  }
  return null;
};

export const convertRequestData = (messageType, data) => {
  if (messageType === "default") {
    return {
      type: data.image ? "FI" : "FT",
      content: data.content,
      image: data.image ? convertImage(data.image, null) : null,
      adFlag: data.isAdd ? "Y" : "N",
      buttons: convertButtons(data.messageButtons),
    };
  } else if (messageType === "wide") {
    return {
      type: "FW",
      content: data.content,
      image: data.image ? convertImage(data.image, data.link) : null,
      adFlag: data.isAdd ? "Y" : "N",
      buttons: convertButtons(data.messageButtons),
    };
  } else if (messageType === "wide_list") {
    return {
      header: data.title,
      type: "FL",
      content: data.content,
      items: [
        ...convertListItems([data.mainItem]),
        ...convertListItems(data.listItems),
      ],
      buttons: convertButtons(data.messageButtons),
    };
  } else if (messageType === "carousel") {
    return {
      type: "FC",
      content: data.content,
      carousels: convertCarouselItems(data.listItems),
      tail: convertCarouselTail(data.messageButtons),
    };
  }
};

export const convertResponseData = (data) => {
  const obj = JSON.parse(data);
  obj.isAdd = obj.adFlag === "Y";
  if (obj.image) {
    obj.link = obj.image.imgLink;
    obj.image = obj.image.imgUrl;
  }
  if (obj.buttons) {
    if (obj.buttons.length === 0) {
      obj.messageButtons = [{ isLink: 'none' }, { isLink: 'none' }];
    } else {
      obj.messageButtons = obj.buttons.map((button) => {
        return {
          name: button.name,
          isLink: button.couponId != null ? 'coupon' : 'link',
          link: button.urlMobile,
          pcLink: button.urlPc,
        };
      });
    }
  }
  if (obj.items && obj.items.length > 0) {
    obj.title = obj.header;
    const mainItem = obj.items.slice(0, 1)[0];
    obj.mainItem = {
      image: mainItem.imgUrl,
      content: mainItem.title,
      link: mainItem.urlMobile,
    };
    obj.listItems = obj.items.slice(1).map((item) => {
      return {
        image: item.imgUrl,
        content: item.title,
        link: item.urlMobile,
      };
    });
  }
  if (obj.carousels) {
    obj.listItems = obj.carousels.map((item) => {
      let buttons

      if (item.attachment.buttons === undefined || item.attachment.buttons.length === 0) {
        buttons = [{ isLink: 'none' }, { isLink: 'none' }];
      } else if (item.attachment.buttons.length === 1) {
        buttons = item.attachment.buttons.map((button) => {
          return {
            name: button.name,
            isLink: button.couponId != null ? 'coupon' : 'link',
            link: button.urlMobile,
            pcLink: button.urlPc,
          };
        })
        buttons.push({ isLink: 'none'})
      } else {
        buttons = item.attachment.buttons.map((button) => {
          return {
            name: button.name,
            isLink: button.couponId != null ? 'coupon' : 'link',
            link: button.urlMobile,
            pcLink: button.urlPc,
          };
        })
      }
      return {
        title: item.header,
        content: item.message,
        messageButtons: buttons,
        image: item.attachment.image.imgUrl,
        link: item.attachment.image.imgLink,
      };
    });

    if (obj.tail) {
      obj.messageButtons = [
        {
          link: obj.tail.urlMobile,
          pcLink: obj.tail.urlPc,
        },
      ];
    } else {
      obj.messageButtons = [{ link: null }];
    }
  }

  return obj;
};

export const convertCodeToMessageType = (code) => {
  if (code === "TEXT" || code === "TEXT_IMAGE") return "default";
  else if (code === "WIDE_IMAGE") return "wide";
  else if (code === "LIST_IMAGE") return "wide_list";
  else if (code === "CAROUSEL") return "carousel";
};

export const getExpectedCustomerCount = async (filters) => {
  const res = await fetchApi.post(API_URL.EXPECTED_CUSTOMER_COUNT, {
    data: filters,
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return 0;
  }
};

export const saveNotification = async (status, request) => {
  const body = cloneDeep(request.body);
  const requestData = {
    ...request,
    messageType: getMessageTypeName(request.messageType, body),
    status: status,
    messageData: JSON.stringify({
      type: getMessageType(request.messageType, body),
      ...convertRequestData(request.messageType, body),
    }),
  };

  const res = await fetchApi.post(API_URL.SAVE_NOTIFICATION, requestData);
  if (res.status === 200) {
    return res.data;
  } else {
    return 0;
  }
};

export const updateNotification = async (id, status, request) => {
  const res = await fetchApi.put(
    API_URL.UPDATE_NOTIFICATION.replace("{id}", id),
    {
      ...request,
      messageType: getMessageTypeName(request.messageType, request.body),
      status: status,
      messageData: JSON.stringify({
        type: getMessageType(request.messageType, request.body),
        ...convertRequestData(request.messageType, request.body),
      }),
    }
  );
  return res
};

export const getVolatilityMessages = async (
  startDate,
  endDate,
  type,
  page = 0,
  size = 9999
) => {
  const searchParam = new URLSearchParams({
    startDate,
    endDate,
    page,
    size,
  });
  if (type !== "ALL") {
    searchParam.set("messageStatus", type);
  }
  const res = await fetchApi.get(
    API_URL.GET_VOLATILITY_MESSAGES + "?" + searchParam.toString()
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

export const getVolatilityMessage = async (id) => {
  const res = await fetchApi.get(
    API_URL.GET_VOLATILITY_MESSAGE.replace("{id}", id)
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const getVolatilityMessageTargets = async (id) => {
  const res = await fetchApi.get(
    API_URL.GET_VOLATILITY_MESSAGE_TARGETS.replace("{id}", id)
  );
  if (res.status === 200) {
    return res.data;
  } else {
    return {};
  }
};

export const cancelReserved = async (id) => {
  const res = await fetchApi.post(
      API_URL.VOLATILITY_MESSAGE_RESERVED_CANCEL.replace("{id}", id)
  );
  return res.data;
};

export const copyMessage = async (id) => {
  const res = await fetchApi.post(
      API_URL.VOLATILITY_MESSAGE_COPY.replace("{id}", id)
  );
  return res.data;
};

export const getContentRequired = (messageType, data) => {
  if (!data)
    return false
  validButtons(data.messageButtons)
  if (messageType === "default") {
    return data.content != null && data.content.length > 0;
  } else if (messageType === "wide") {
    return isWideItemRequired(data);
  } else if (messageType === "wide_list") {
    return (
      isWideItemRequired(data.mainItem) &&
      data.listItems?.filter((item) => isWideItemRequired(item)).length > 0
    );
  } else if (messageType === "carousel") {
    return (
      data.listItems?.filter((item) => isTitleContentRequired(item)).length > 1
    );
  }
};

export const testSend = async (userIdList, messageType, messageData) => {
  const res = await fetchApi.post(API_URL.TEST_VOLATILITY_MESSAGE, {
    userIdList: userIdList,
    messageType: messageType,
    messageData: messageData
  });
  if (res.status === 200) {
    return res.data;
  } else {
    return [];
  }
};

const isWideItemRequired = (data) => {
  return (
    data && data.link != null &&
    data.content?.length > 0 &&
    data.image != null
  );
};

const isTitleContentRequired = (data) => {
  return (
      data &&
      data.image != null &&
      data.title?.length > 0 &&
      data.content?.length > 0 &&
      validButtons(data.messageButtons)
  );
};

const validButtons = (buttons) => {
  for (const button of buttons) {
    if (button.isLink !== 'none') {
      if (button.name?.length === 0)
        return false
    }
    if (button.isLink === 'link') {
      if (button.link?.length === 0)
        return false
    }
    if (button.isLink === 'coupon') {
      if (!button.coupon)
        return false
    }
  }
  return true
}
