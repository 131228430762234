import InputGroup from "../InputGroup";
import TextInput from "../TextInput";
import { Box } from "@mui/material";
import {EVENT_TYPES} from "../../../constants/menus";

export const EventDefault = ({event, onChangeEventName, onSelectType}) => {
    const eventType = event.type ?? "roulette"

  return (
      <div className="flex-col divide-y">
        <InputGroup
            label={"이벤트명"}
            isRequired={true}
            className="py-8"
        >
          <p className="text-gray-500 text-sm mb-2">
            이벤트명을 입력해 주세요.
          </p>
          <TextInput
              value={event.name}
              placeholder="이벤트명을 입력해 주세요."
              onChange={(e) => onChangeEventName(e.target.value)}
              maxLength={30}
          />
        </InputGroup>
        <InputGroup
            label={"이벤트 타입"}
            isRequired={true}
            className="py-8"
        >
          <p className="text-gray-500 text-sm mb-2">
            어떤 타입으로 보내실지 골라주세요.
          </p>
          <div className="grid grid-cols-2 gap-4 text-sm">
            {EVENT_TYPES.map((type, index) => (
                <div
                    key={"message-type" + index}
                    className={
                        "flex border rounded-lg cursor-pointer " +
                        (type.key === eventType
                            ? "border-[#4046e3] bg-indigo-50"
                            : "border-[#dbdbea]") +
                        (type.key === "preparing" && " bg-gray-200")
                    }
                    onClick={() => {
                        if (type.key !== "preparing") {
                            onSelectType(type.key)
                        }
                    }}
                >
                  <Box className="w-[240px] bg-[#eaecef] min-w-[240px] rounded-l-lg" style={{minHeight: "180px"}}>
                    <Box className="w-full ft-selection-preview-box">
                      <Box className={type.imageClass}></Box>
                    </Box>
                  </Box>
                  <div className="flex-col justify-center p-8 text-gray-600 text-xs">
                    <h5 className="font-medium text-sm">{type.title}</h5>
                    <p className="mt-2 text-xs">{type.description}</p>
                    <button className="text-indigo-600 w-fit mt-2">
                      작성하기 >
                    </button>
                  </div>
                </div>
            ))}
          </div>
        </InputGroup>
      </div>
  );
};

export default EventDefault;
