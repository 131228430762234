import InputGroup from "../InputGroup";
import NormalButton from "../../NormalButton";
import {useState} from "react";
import {kakaoImageUpload} from "../../../api/file/file.api";
import HighlightWithinTextarea from "react-highlight-within-textarea";

const BenefitMessageSettingForm = ({image, onImageChange, message, onMessageChange, regex, variable}) => {
    const [isVariablePopup, setVariablePopup] = useState(false)

    const uploadImage = (e) => {
        kakaoImageUpload(e.target.files[0]).then(({data}) => {
            if (!data?.imageUrl) {
                alert(data.message)
            } else {
                onImageChange(data?.imageUrl)
            }
            e.target.value = null
        })
    }

    const addVariable = (text) => {
        const variable = message + `[${text}]`
        onMessageChange(variable)
        setVariablePopup(false);
    };

    return (
        <>
            <InputGroup
                label={"메시지 이미지"}
                isRequired={true}
                className="py-8"
            >
                <div className="flex gap-4">
                    <input
                        type="text"
                        className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
                        placeholder="업로드할 파일 선택"
                        maxLength={30}
                        readOnly
                        value={image}
                        required
                    />
                    <label
                        htmlFor={"prize_image"}
                        className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
                    >
                        첨부
                    </label>
                    <input
                        type="file"
                        id={"prize_image"}
                        className="hidden"
                        onChange={(e) => uploadImage(e)}
                    />
                </div>
                <div className="text-grey-4" style={{fontSize: "12px"}}>가로 500px 이상, 최소 비율 2:1, 최대 비율 3:4 지원 포맷: png,jpeg (최대 1MB)</div>
            </InputGroup>
            <InputGroup
                label={"당첨 메시지"}
                isRequired={true}
                className="py-8"
            >
                <div className="text-grey-5">혜택의 당첨 내용을 설정해 주세요.</div>
                <div>
                    <div className="block w-full p-2 pb-6 pr-8 text-xs text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-indigo-500 focus:border-indigo-500 relative">
                        <HighlightWithinTextarea
                            value={message}
                            onChange={(value) => onMessageChange(value)}
                            highlight={[
                                {
                                    highlight: regex,
                                    className: "variable_highlight",
                                },
                            ]}
                            className=""
                        />
                        <span className="absolute end-3 bottom-2 text-xs text-gray-400">
                            {message?.length || 0}/400자
                        </span>
                    </div>
                        <div className="flex justify-end relative pt-2">
                            <NormalButton
                                className="whitespace-nowrap px-4 py-2 text-sm"
                                onClick={() => setVariablePopup(!isVariablePopup)}
                            >
                                + 변수 설정
                            </NormalButton>
                            {isVariablePopup && (
                                <ul className="absolute -bottom-24 right-0 bg-white rounded-lg text-sm shadow-lg border border-gray-200 text-gray-600 ">
                                    <li
                                        className="hover:bg-gray-50 cursor-pointer py-3 px-6 rounded-t-lg"
                                        onClick={() => addVariable(variable[0])}
                                    >
                                        {variable[0]}
                                    </li>
                                    <li
                                        className="hover:bg-gray-50 cursor-pointer py-3 px-6 rounded-b-lg"
                                        onClick={() => addVariable(variable[1])}
                                    >
                                        {variable[1]}
                                    </li>
                                </ul>
                            )}
                        </div>
                </div>
            </InputGroup>
        </>
    )
}

export default BenefitMessageSettingForm