import { useCallback, useEffect, useState } from "react";
import BenefitMessagePreview from "../../layout/BenefitMessagePreview";
import { fileUpload } from "../../../api/file/file.api";
import Firework from "../../../assets/images/coupon_result_firework.svg";
import GrayButton from "../../GrayButton";
const CouponResultForm = ({
  onChange,
  isValidated,
  couponImage,
  additionalTitle,
  additionalDescription,
  couponData,
}) => {
  const [uploadFileName, setUploadFileName] = useState();
  useEffect(() => {
    if (couponImage) {
      setUploadFileName(decodeURI(couponImage.split("/").reverse()[0]));
    }
  }, [couponImage]);

  const handleChange = useCallback(
    (key, value) => {
      let updateValue = {
        couponImage,
        additionalTitle,
        additionalDescription,
      };
      updateValue[key] = value;
      onChange(updateValue);
    },
    [couponImage, additionalTitle, additionalDescription, onChange]
  );

  const onChangeFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      handleFileUpload(file);
    }
  };

  const handleFileUpload = useCallback(
    async (file) => {
      const res = await fileUpload(file);
      handleChange("couponImage", res.data);
    },
    [handleChange]
  );

  const renderFormWrap = (
    <div className="flex-col gap-20 w-full">
      <ul className="flex-col gap-8">
        <li>
          <div className="flex items-center mb-1">
            <label className="text-sm font-semibold text-gray-900 relative flex items-center">
              <div className="bg-black text-white text-xs rounded-full h-5 w-5 text-center font-medium leading-5 mr-2">
                1
              </div>
              쿠폰 이미지
              <span className="absolute text-red-500 -end-2">*</span>
            </label>
          </div>
          <p className="text-gray-500 text-sm mb-2">
            당첨 이미지를 설정해 주세요.
          </p>
          <div className="flex gap-4">
            <input
              type="text"
              className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2"
              placeholder="업로드할 파일 선택"
              maxLength={30}
              readOnly
              value={uploadFileName}
              required
            />
            <label
              htmlFor={"intro_background"}
              className="text-sm px-6 whitespace-nowrap border rounded border-gray-500 bg-white text-gray-500 hover:bg-gray-100 text-center transition flex items-center cursor-pointer"
            >
              첨부
            </label>
            <input
              type="file"
              id={"intro_background"}
              className="hidden"
              onChange={onChangeFile}
            />
          </div>
          <p className="text-gray-400 text-xs mt-4">
            권장 사이즈 : 800 X 600px / 지원파일 : JPG, JPEG, PNG (최대 2MB)
          </p>
        </li>
        <li>
          <div className="flex items-center mb-1">
            <label className="text-sm font-semibold text-gray-900 relative flex items-center">
              추가 안내
            </label>
          </div>
          <p className="text-gray-500 text-sm mb-2">
            사용 불가/사용 가능 안내등 필요한 내용을 설정해 주세요.
          </p>
          <div className="flex-col gap-4">
            <div className="relative">
              <input
                type="text"
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2 "
                placeholder="제목을 입력해 주세요"
                value={additionalTitle || ""}
                onChange={(e) =>
                  handleChange("additionalTitle", e.target.value)
                }
                maxLength={20}
              />
              <span className="absolute end-3 bottom-3 text-xs text-gray-400">
                {additionalTitle?.length || 0}/20자
              </span>
            </div>
            <div className="relative">
              <textarea
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2 "
                placeholder="내용을 입력해 주세요"
                onChange={(e) =>
                  handleChange("additionalDescription", e.target.value)
                }
                rows={6}
                maxLength={3000}
                onResize={false}
              >
                {additionalDescription}
              </textarea>
              <span className="absolute end-3 bottom-3 text-xs text-gray-400">
                {additionalDescription?.length || 0}/3000자
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
  return (
    <BenefitMessagePreview
      preview={
        <div className="relative h-full bg-repeat-round bg-contain bg-white overflow-auto">
          <div className="h-[28px] bg-[#373ad9] text-white font-bold text-center font-sm">
            Headless
          </div>
          <div
            className="bg-[#E5E6FF] absolute top-[28px] w-full h-[150px]"
            style={{
              backgroundImage: `url(${Firework})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center 0",
            }}
          />
          <div className="absolute w-full items-center flex-col">
            <h4 className="text-[#4046E3] font-medium mt-4 w-4/5 break-words text-center">
              {couponData.name}
            </h4>
            <p className="text-xs font-medium">
              {couponData.price?.toLocaleString()}원
            </p>
            <div className="p-4 bg-white rounded-lg w-4/5 text-[10px] text-center shadow-lg mt-2">
              <p>결제 시 직원에게 보여주세요.</p>
              <p className="text-[#4046E3]">사용기한 0000-00-00 00:00까지</p>
              {couponImage && (
                <img src={couponImage} className="w-4/5 mx-auto mt-2" />
              )}
              {couponData.couponType === "DIRECT" ? (
                <GrayButton className="py-1.5 mt-4">직원 사용 확인</GrayButton>
              ) : (
                <GrayButton className="py-4 mt-4">바코드 영역</GrayButton>
              )}
            </div>
            <div className="mt-4 text-[10px] text-gray-500 text-left w-4/5">
              <p className="font-bold">{additionalTitle}</p>
              <p className="whitespace-break-spaces w-full break-words">
                {additionalDescription}
              </p>
            </div>
          </div>
        </div>
      }
      content={renderFormWrap}
    />
  );
};

export default CouponResultForm;
