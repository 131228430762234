import {
  selector,
  useRecoilRefresher_UNSTABLE,
  useRecoilStateLoadable,
  useRecoilValue,
} from "recoil";
import { folderSelector, spotSelector } from "../../store/selector/spot";
import { useCallback, useEffect, useMemo, useState } from "react";
import ActiveButton from "../ActiveButton";
import { updateSpotsFolder } from "../../api/spot/spot.api";
import { couponFamilySelector } from "../../store/selector/coupon";
import Loading from "../Loading";
import Tooltip from "../Tooltip";
import moment from "moment";
import SortableTableHeader from "../table/SortableTableHeader";
import GrayButton from "../GrayButton";
import InfoButton from "../InfoButton";
import { couponSearchTypes } from "../../constants/search_types";
import Pagination from "../Pagination";
import { renderCouponCompanyName } from "../../libs/helper";

const CouponConnectionForm = ({ onClick, used }) => {
  const [isLoading, setLoading] = useState(true);
  const [searchType, setSearchType] = useState("STATUS");
  const [searchValue, setSearchValue] = useState("ACTIVATED");
  const [page, setPage] = useState(1);
  const [size] = useState(10);
  const [sort, setSort] = useState("createdAt,desc");
  const [totalPage, setTotalPages] = useState(0);
  const [coupons, setCoupons] = useState([]);
  const [loading] = useRecoilStateLoadable(
    couponFamilySelector(
      [searchType, searchValue, page - 1, size, sort, used].join("|")
    )
  );
  const refresh = useRecoilRefresher_UNSTABLE(
    couponFamilySelector(
      [searchType, searchValue, page - 1, size, sort, used].join("|")
    )
  );
  const [sortKey, sortDirection] = sort.split(",");
  const headers = [
    { key: "company", label: "쿠폰 업체", sortable: false },
    { key: "name", label: "쿠폰명", sortable: false },
    {
      key: "availableDate",
      label: "유효기간",
      sortable: false,
      className: "text-center",
    },
    {
      key: "price",
      label: "쿠폰 금액",
      sortable: false,
      className: "text-center",
    },
    {
      key: "type",
      label: "쿠폰 형식",
      sortable: false,
      className: "text-center",
    },
    {
      key: "createdAt",
      label: "혜택 설정일",
      sortable: false,
      className: "text-center",
    },
    {
      key: "edit",
      label: "선택하기",
      sortable: false,
      className: "text-center",
    },
  ];

  const fetchSpotState = useCallback(() => {
    if (loading.state !== "loading") {
      const data = loading.contents;
      setLoading(false);
      setCoupons(data.content);
      setTotalPages(data.totalPages);
    }
  }, [loading]);

  const handleChangeSearchType = (value) => {
    let searchValue = null;
    if (value) {
      const searchType = couponSearchTypes.filter(
        (ct) => ct.value === value
      )[0];
      if (searchType.items && searchType.items.length > 0) {
        searchValue = searchType.items[0].value;
      }
    }
    setSearchValue(searchValue);
    setSearchType(value);
  };

  useEffect(() => {
    fetchSpotState();
  }, [fetchSpotState]);

  const searchTextRender = (type) => {
    const searchType = couponSearchTypes.filter((ct) => ct.value === type)[0];
    if (type === "company") {
      return (
        <select
          className="w-100 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block p-2"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        >
          {searchType.items.map((item) => {
            return (
              <option
                value={item.value}
                key={"search_value_" + type + item.value}
              >
                {item.label}
              </option>
            );
          })}
        </select>
      );
    }
  };
  return (
    <div className="py-4">
      <div className="w-full h-12 border-red-500 border-l-4 bg-opacity-20 bg-red-200 text-red-500 px-4 text-sm flex items-center rounded">
        활성화된 쿠폰만 조회가 가능합니다. 쿠폰이 조회되지 않는다면, ‘쿠폰
        리스트’에서 확인해 주세요.
      </div>
      <div className="flex w-full gap-2 my-4">
        <select
          className="w-100 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block p-2"
          onChange={(e) => handleChangeSearchType(e.target.value)}
        >
          <option value="">전체 보기</option>
          {couponSearchTypes.map((type) => {
            return (
              <option value={type.value} key={"search_filter_" + type.value}>
                {type.label}
              </option>
            );
          })}
        </select>
        {searchTextRender(searchType)}
      </div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 border mb-4 relative">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 rounded-tl border-b">
          <tr className="divide-x">
            {headers.map((header, index) => {
              return (
                <SortableTableHeader
                  key={"coupon-connection-table-header" + index}
                  header={header}
                  sortKey={sortKey}
                  sortDirection={sortDirection}
                />
              );
            })}
          </tr>
        </thead>
        {isLoading ? (
          <tbody>
            <tr>
              <td colSpan={7} className="text-center">
                <Loading className="w-12 h-12 mx-auto my-6" />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {coupons.map((coupon, index) => {
              const cs = "px-6 py-3 ";
              return (
                <tr
                  className="bg-white border-b hover:bg-gray-50 divide-x"
                  key={"coupons_connection_" + index}
                >
                  <td className={cs}>
                    {renderCouponCompanyName(coupon.company)}
                  </td>
                  <td className={cs}>{coupon.name}</td>
                  <td className={cs + "text-center"}>
                    {coupon.period.toLocaleString()}일
                  </td>
                  <td className={cs + "text-center"}>
                    {coupon.price.toLocaleString()}원
                  </td>

                  <td className={cs + "text-center"}>-</td>
                  <td className={cs + "text-center"}>
                    {moment(coupon.createdAt)
                      .add("9", "hours")
                      .format("YYYY-MM-DD")}
                  </td>
                  <td className={cs}>
                    <InfoButton
                      className="text-sm py-2 px-4 whitespace-nowrap mx-auto"
                      onClick={() => onClick(coupon)}
                    >
                      선택
                    </InfoButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      <div className="w-100 flex justify-center mt-2">
        <Pagination page={page} totalPages={totalPage} setPage={setPage} />
      </div>
    </div>
  );
};

export default CouponConnectionForm;
