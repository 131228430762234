import { Box } from "@mui/material";
import MessagePreview from "./preview/MessagePreview";
import MessageSetting from "./setting/MessageSetting";

const MessageContent = ({ data, messageType, onChange }) => {
  const updateData = (data) => {
    onChange(data);
  };
  return (
    <Box className="settings-box">
      <Box>
        <Box sx={{ display: "flex", gap: "60px" }}>
          <MessageSetting
            data={data}
            type={messageType}
            onChange={updateData}
          />
          <MessagePreview data={data} type={messageType} />
        </Box>
      </Box>
    </Box>
  );
};

export default MessageContent;
