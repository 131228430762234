import { faMessage } from "@fortawesome/free-solid-svg-icons";
import SettingsLayout from "../layout/SettingsLayout";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  convertRequestData,
  getContentRequired,
} from "../../api/message/volatility-message.api";
import {
  getAutomateMessage,
  postAutomateMessageList
} from "../../api/message/automate-messages.api";
import AutomateMessageSettings from "./AutomateMessageSettings";

const tabs = [
  { label: "기본 설정", value: "default" },
  { label: "콘텐츠 작성하기", value: "content" },
  { label: "발송 조건 설정", value: "condition" },
  { label: "최종 확인", value: "confirm" },
];
const AutomateMessageEdit = () => {
  const [query] = useSearchParams();
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [isConfirm, setIsConfirm] = useState(false)
  const [automateMessageData, setAutomateMessageData] = useState()

  const id = query.get("id");

  const handleConfirm = async () => {
    setIsConfirm(true)
    automateMessageData.message = JSON.stringify(convertRequestData(automateMessageData.messageType, automateMessageData.previewMessage))
    automateMessageData.previewMessage = JSON.stringify(automateMessageData.previewMessage)
    automateMessageData.status = false
    await postAutomateMessageList(automateMessageData);
    alert("수정 되었습니다.");
    window.location.href = "/automate-message"
  }

  useEffect(() => {
    getAutomateMessage(id).then((res) => {
      const data = res.data
      data.previewMessage = JSON.parse(data.previewMessage)
      setAutomateMessageData(data)
    })
  }, [id]);

  const getIsConfirm = () => {
    return (
        !isConfirm && automateMessageData && getContentRequired(automateMessageData.messageType, automateMessageData.previewMessage)
    )
  }

  return (
      <SettingsLayout
          title={"자동화 메시지"}
          icon={faMessage}
          tabs={tabs}
          activeTab={activeTab}
          onConfirm={handleConfirm}
          onChange={setActiveTab}
          isConfirm={getIsConfirm()}
          confirmText={"설정 완료"}
          onClose={() => window.location.href = "/automate-message"}
      >
        <AutomateMessageSettings
            activeTab={activeTab}
            onChangeTab={setActiveTab}
            automateMessageData={automateMessageData}
            setAutomateMessageData={setAutomateMessageData}
        />
      </SettingsLayout>
  );
};
export default AutomateMessageEdit;
