import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GrayButton from "../GrayButton";
import {
  faArrowLeft,
  faBullseye, faC,
  faCircleQuestion,
  faComputerMouse,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import {MESSAGE_TARGET_FILTER, MESSAGE_TYPES} from "../../constants/menus";
import moment from "moment";
import Tooltip from "../Tooltip";
import {
  convertCodeToMessageType, convertResponseData, copyMessage, getVolatilityMessage,
} from "../../api/message/volatility-message.api";
import {useSearchParams} from "react-router-dom";
import SortableTableHeader from "../table/SortableTableHeader";
import MessagePreview from "./content/preview/MessagePreview";
import {getCampaignMessages} from "../../api/message/messages.api";
import {Button, Pagination, Table} from "omoplay";

export const VolatilityMessageDetail = () => {
  const [query] = useSearchParams();
  const id = query.get("id")
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [targets, setTargets] = useState([]);
  const [volatilityData, setVolatilityData] = useState({})
  const [isDataLoading, setDataLoading] = useState(true)
  const [previewData, setPreviewData] = useState({
    isAdd: true,
    messageButtons: [{ isLink: 'none' }, { isLink: 'none' }],
  })
  const [messageType, setMessageType] = useState(MESSAGE_TYPES[0].key)
  const [filterCategories, setFilterCategories] = useState([])
  const [sortKey, sortDirection] = "createdAt,desc".split(",");

  const requestVolatilityMessages = useCallback(async (id) => {
    const res = await getVolatilityMessage(id);
    if (res.data) {
      const data = res.data;
      setVolatilityData(data);
      setFilterCategories(Array.from(new Set(data.filters.map((item) => item.category))))
      setMessageType(convertCodeToMessageType(data.messageType));
      setPreviewData(convertResponseData(data.messageData));
      setDataLoading(false)
    }
  });

  useEffect(() => {
    setDataLoading(true);
    requestVolatilityMessages(id);
  }, []);
  
  const getMessageTargets = useCallback(async () => {
    const res = await getCampaignMessages(id, page - 1);
    if (res.data) {
      setTargets(res.data.map(message => {
        return [
            message.name,
          message.phoneNumber,
          <div style={{fontWeight: "700"}}>
            {displayStatus(message.result, message.errorCode)}
          </div>,
          message.sendAt
        ]
      }));
      setTotalPages(res.data.length === 0 ? 0 : res.data[0].totalSize);
    }
  });

  useEffect(() => {
    getMessageTargets();
  }, [page]);


  const displayStatus = (status, errorCode) => {
    if (status === "2") {
      return <span>발송 성공</span>
    } else if (status === "4") {
      return <><span style={{color: "#CE326C"}}>발송 실패</span><br/><span>({errorCode})</span></>
    } else {
      return <span>결과 대기</span>;
    }
  };

  const headers = [
    { name: "이름" },
    { name: "전화번호" },
    { name: "상태" },
    { name: "발송 일시" },
  ];

  const couponHeaders = [
    { label: "쿠폰 업체", value: "company", sortable: false },
    { label: "쿠폰명", value: "name", sortable: false },
    { label: "유효기간", value: "period", sortable: false, className: "text-center" },
    { label: "쿠폰 금액", value: "price", sortable: false, className: "text-center" },
    { label: "쿠폰 형식", value: "type", sortable: false, className: "text-center" },
    { label: "쿠폰 사용 / 쿠폰 발급 수", value: "used", sortable: false, className: "text-center bg-indigo-100 text-indigo-600" },
  ];

  if (isDataLoading) {
    return <></>
  }

  return (
    <div>
      <div className="mb-4 gap-4" style={{ width: "1248px" }}>
        <div>
          <div className="flex justify-between items-center mb-2">
            <div className="flex gap-4">
              <div className="w-10 h-10">
                <GrayButton
                    className="w-10 h-10"
                    onClick={() => window.location.href = ("/volatility-message")}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </GrayButton>
              </div>
              <h4 className="text-3xl font-medium">{volatilityData.name}</h4>
            </div>
            <div>
              <Button variants="dark" value="복제하기" onClick={() => {
                if (window.confirm("정말 복제하시겠습니까?")) {
                  copyMessage(id).then(() => {
                    window.location.href = "/volatility-message"
                  })
                }
              }} />
            </div>
          </div>
          <div className="flex-col gap-20 mt-8">
            <div>
              <div className="flex-col gap-2">
                <label className="text-lg font-medium text-gray-900 relative">
                  메시지 발송 결과
                </label>
                <div className="white border border-gray-300 rounded p-4 bg-white">
                  <p>
                    <span className="font-semibold">발송 일시 : </span>
                    {moment(volatilityData.issuedAt).format("YYYY-MM-DD HH:mm")}
                  </p>
                </div>
                <div className="white border border-gray-300 rounded bg-white divide-x h-[135px] flex">
                  <div className="flex-col justify-between w-1/3 p-4 h-full">
                    <div className="flex gap-2 items-center">
                      <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                        <FontAwesomeIcon icon={faBullseye} size="sm" />
                      </div>
                      <p className="font-semibold text-sm">
                        발송 성공 / 발송 시도 고객 수
                        <div className="inline-block align-text-top ml-2">
                          <Tooltip
                            top={"top-4"}
                            message={
                              "친구 삭제,차단 등으로 고객 성공 수에 차이가 발생합니다."
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleQuestion}
                              size={"lg"}
                              color={"#999"}
                            />
                          </Tooltip>
                        </div>
                      </p>
                    </div>
                    <div className="flex justify-end text-right">
                      <div>
                        <p className="text-gray-500">
                          {volatilityData.sendCount?.toLocaleString()}명 시도
                        </p>
                        <p className="font-bold text-2xl">
                          {volatilityData.successCount?.toLocaleString()}명
                          <span className="font-medium text-base ml-1">
                            <td>
                              (
                              {volatilityData.successCount && volatilityData.sendCount
                                ? Math.round(
                                    (volatilityData.successCount / volatilityData.sendCount) * 1000
                                  ) / 10
                                : 0}
                              %)
                            </td>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex-col justify-between w-1/3 p-4 h-full">
                    <div className="flex gap-2 items-center">
                      <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                        <FontAwesomeIcon icon={faComputerMouse} size="sm" />
                      </div>
                      <p className="font-semibold text-sm">
                        링크 클릭 수
                        <div className="inline-block align-text-top ml-2">
                          <Tooltip
                            top={"top-4"}
                            message={
                              "중복을 포함한 링크 클릭 수를 의미합니다. 포함된 링크가 없는 경우, 숫자가 카운트 되지 않습니다."
                            }
                          >
                            <FontAwesomeIcon
                              icon={faCircleQuestion}
                              size={"lg"}
                              color={"#999"}
                            />
                          </Tooltip>
                        </div>
                      </p>
                    </div>
                    <div className="flex justify-end text-right">
                      <p className="font-bold text-2xl">
                        {volatilityData.linkCount?.toLocaleString()}번
                      </p>
                    </div>
                  </div>
                  <div className="flex-col justify-between w-1/3 p-4 h-full">
                    <div className="flex gap-2 items-center">
                      <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                        <FontAwesomeIcon icon={faDollarSign} size="sm" />
                      </div>
                      <p className="font-semibold text-sm">발송 비용</p>
                    </div>
                    <div className="flex justify-end text-right">
                      <p className="font-bold text-2xl">
                        ₩{volatilityData.totalPrice?.toLocaleString()}원
                      </p>
                    </div>
                  </div>
                  <div className="flex-col justify-between w-1/3 p-4 h-full">
                    <div className="flex gap-2 items-center">
                      <div className="rounded w-6 h-6 bg-indigo-100 text-indigo-600 flex justify-center items-center">
                        <FontAwesomeIcon icon={faC} size="sm" />
                      </div>
                      <p className="font-semibold text-sm">쿠폰 사용 / 쿠폰 발급 수</p>
                    </div>
                    <div className="flex justify-end text-right">
                      <div>
                        <p className="text-gray-500">
                          {volatilityData.issuedCouponCount}개 발급
                        </p>
                        <p className="font-bold text-2xl">
                          {volatilityData.usedCouponCount}개
                          <span className="font-medium text-base ml-1">
                            <td>
                              (
                              {volatilityData.usedCouponCount && volatilityData.issuedCouponCount
                                  ? Math.round(
                                  (volatilityData.usedCouponCount / volatilityData.issuedCouponCount) * 1000
                              ) / 10
                                  : 0}
                              %)
                            </td>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <label className="text-lg font-medium text-gray-900 relative">
                  발송 조건
                </label>
                <p className="text-gray-500 mb-2">
                  다른 메시지들과 구분하기 위한 캠페인명을 작성해 주세요.
                </p>
              </div>
              <ul className="flex-col gap-4 mt-4">
                {filterCategories.length > 0 ?
                  filterCategories.map((value, i) => {
                    const categoryIndex = MESSAGE_TARGET_FILTER.findIndex(
                      (parent) => parent.value === value
                    );
                    return (
                      <li
                        key={"confirm_category_" + i}
                        className="flex gap-4 items-center"
                      >
                        <div className="border border-gray-300 rounded flex w-5/6">
                          <div className="min-w-[160px] bg-gray-100 p-4 border-r border-gray-300 flex rounded-l">
                            <p className="mt-1">
                              {MESSAGE_TARGET_FILTER[categoryIndex].label}
                            </p>
                          </div>
                          <div className="flex flex-wrap gap-4 px-4 py-4 bg-white w-full rounded-r">
                            {volatilityData.filters
                              .filter((item) => item.category === value)
                              .map((item, j) => {
                                return (
                                  <div
                                    key={"confirm_category_" + i + "_item_" + j}
                                    className="rounded py-1 bg-indigo-100 text-indigo-600 px-4 font-medium"
                                  >
                                    {item.label}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="w-1/6 flex">
                          {i < filterCategories.length - 1 && (
                            <p className="ml-4">
                              이고 <span className="font-semibold">(AND)</span>
                            </p>
                          )}
                        </div>
                      </li>
                    )
                  }) : (
                        <li
                            key={"confirm_category_0"}
                            className="flex gap-4 items-center"
                        >
                          <div className="border border-gray-300 rounded flex w-5/6">
                            <div className="min-w-[160px] bg-gray-100 p-4 border-r border-gray-300 flex rounded-l">
                              <p className="mt-1">
                                발송 조건
                              </p>
                            </div>
                            <div className="flex flex-wrap gap-4 px-4 py-4 bg-white w-full rounded-r">
                              <div
                                  key={"confirm_category_0_item_0"}
                                  className="rounded py-1 bg-indigo-100 text-indigo-600 px-4 font-medium"
                              >
                                전체
                              </div>
                            </div>
                          </div>
                        </li>
                  )}
              </ul>
            </div>
            <div>
              <div>
                <label className="text-lg font-medium text-gray-900 relative">
                  발급 쿠폰
                </label>
                <p className="text-gray-500 mb-8">
                  메시지에 포함된 발급 쿠폰 리스트 입니다.
                </p>
              </div>
              <div className="flex-col bg-white p-2 border-gray-300 border rounded">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 border relative">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 rounded-tl border-b ">
                  <tr className="divide-x">
                    {couponHeaders.map((header, index) => {
                      return (
                          <SortableTableHeader
                              columnStyle="px-4 py-2"
                              key={"coupon-connection-table-header" + index}
                              header={header}
                              sortKey={sortKey}
                              sortDirection={sortDirection}
                          />
                      );
                    })}
                  </tr>
                  </thead>
                  <tbody className="text-xs">
                  {volatilityData.couponList?.map((coupon, index) => {
                    const cs = "px-3 py-2";
                    return (
                        <tr
                            className="bg-white border-b  hover:bg-gray-50 divide-x"
                            key={"partner-user-" + index} style={{height: "62px"}}
                        >
                          <td className={cs}>{coupon.company}</td>
                          <td className={cs}>{coupon.name}</td>
                          <td className={`${cs}, text-center`}>
                            <p>[발급일]</p>
                            <p>발급일로 부터 {coupon.period}일</p>
                          </td>
                          <td className={`${cs}, text-center`}>{coupon.price.toLocaleString()}원</td>
                          <td className={`${cs}, text-center`}>{coupon.type}</td>
                          <td className={`${cs}, text-center`}>
                            <span className="text-indigo-600 font-bold">{coupon.used.toLocaleString()}개</span>
                            ({(Math.round(coupon.used / coupon.issued * 1000) / 10)}%) / {coupon.issued.toLocaleString()}개
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <label className="text-lg font-medium text-gray-900">
                발송 메시지
              </label>
              <div className="mt-4"></div>
              <MessagePreview type={messageType} data={previewData} isDetail={true} />
            </div>
            <div>
              <label className="text-lg font-medium text-gray-900">
                대상 고객 리스트
              </label>
            </div>
            <div className="flex-col bg-white p-2 border-gray-300 border rounded">
              <Table headers={headers} data={targets} />
              <div className="flex justify-center" style={{marginTop: "24px"}}>
                <Pagination rowSize={10} totalSize={totalPages} page={page} onPaging={(page) => setPage(page)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VolatilityMessageDetail;
